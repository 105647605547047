import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Checkbox, Form, Input, message, Modal, Steps } from "antd";

import {
  readyToStart,
  readyToClose,
  readyToCommit,
  setInterested,
  getBusiness,
  businessSummary,
  getBusinessInvolveRequest,
  acceptInvolveRequest,
  rejectInvolveRequest,
} from "../../redux/business/thunks/businessThunk";
import { getNDA, signNDA } from "../../redux/business/thunks/ndaThunk";
import { setActiveInvestmentId, setActivePortfolioTabKey } from "../../redux/reports/actions/reportsActions";
import { disableBusiness, enableBusiness } from "../../redux/business/thunks/newBusinessThunks";
import { getStates } from "../../redux/dictionaries/thunks/dictionariesThunks";
import { removeStates } from "../../redux/dictionaries/actions/dictionariesActions";
import { removeCurrentBusiness, resetInvolveRequestData } from "../../redux/business/actions/businessActions";
import history from "../../history";
import CommonUtils from "../../utils/CommonUtils";
import { BID_REFRESH_TIME, SERVER_URL } from "../../utils/constants";
import { getMediaFileFromServer } from "../../utils/getMediaFileFromServer";
import { handleLoading } from "../../utils/handleLoading";
import { handleErrorMessage } from "../../utils/handleErrorMessage";
import { formatNumber } from "../../utils/formatNumber";
import { findDictionaryByID } from "../../utils/findDictionaryByID";
import { findValueFromDictionary } from "../../utils/findValueFromDictionary";
import { goBack } from "../../utils/goBack";
import { validURL } from "../../utils/validURL";

import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import PopUp from "../../components/PopUp/PopUp";
import NumericInput from "../../components/NumericInput/NumericInput";
import InvestmentData from "./components/InvestmentData";
import AuctionData from "./components/AuctionData";
import InvestmentActions from "./components/InvestmentActions";
import AuctionActions from "./components/AuctionActions";
import "./style.scss";
import {
  addExternalBid,
  addInternalBid,
  closeOffer,
  externalPublish,
  getOffer,
  getOfferBidRequest,
  internalPublish,
  removeOfferAdmin,
  removeOfferInvestor,
  requestExternalSales,
  searchBids,
  searchOfferBidsCount
} from "../../redux/business/thunks/offersThunk";
import InternalPublishForm from "./components/AuctionActions/InternalPublishForm";
import ExternalPublishForm from "./components/AuctionActions/ExternalPublishForm";
import DeleteOfferForm from "./components/AuctionActions/DeleteOfferForm";
import { calculateTime, compareTimes, sixMonthDiff } from "../../utils/calculateTime";
import RequestExternalPublishForm from "./components/AuctionActions/RequestExternalPublishForm";
import { clearOffer, resetOfferBidRequest } from "../../redux/business/actions/offersAction";
import { handleOperationSuccess } from "../../utils/handleOperationSuccess";
import { getBusinessReport } from "../../redux/reports/thunks/reportsThunk";
import { ToolTip } from "../../components/Tooltip";
import { convertToUTC } from "../../utils/convertToUTC";
import { calcFieldsValue } from '../../utils/calculateOfferFieldsValue';
const { Step } = Steps;

let calcFieldValues = {
  SPV: 0,
  operatingCompanyOwned: 0,
  originalSharePrice: 1,
  finalAmount: 0,
  distributions: 0,
  InvestmentToBeSold: 0,
  ROI: 0,
  ROIAnnualized: 0,
  RDE: 0,
  RDEAnnualized: 0,
};

class Project extends Component {
  constructor(props) {
    super(props);

    this.signCanvas = createRef({});
    this.getOfferRef = createRef();

    this.notInterestedTarget = null; // can be either 'no' or 'not_interested'
    this.state = {
      antSteps: window.innerWidth > 600 && window.innerWidth < 1200 ? 'horizontal' : 'vertical',
      offerLeftTime: null,
      requestExternalModalVisible: false,
      submitModalVisible: false,
      internalModalVisible: false,
      externalModalVisible: false,
      removeOfferModalVisible: false,

      isAuction: false,
      popupVisible: false,
      popupSuccessVisible: false,
      popupNdaVisible: false,
      bidPopupVisible: false,
      isNDACanvasValid: true,
      popupVideoVisible: false,
      internalPublishSuccess: false,
      externalPublishSuccess: false,
      closeOfferSuccess: false,
      videoSrc: null,
      ndaLogged: false,
      openToCommitment: false,
      dealClosed: false,
      isNdaCheckboxChecked: false,
      signImageURL: null,
      amountPopupVisible: false,
      submissionDetailsVisible: false,
      nonOnboardedMessagePopupVisible: false,
      videoStreamURL: '',
      bidAmount: 0,
      investmentActionMessagePopupInfo: {
        type: '',
        visible: false,
      }
    }
  }
  getCurrentOffer = () => {
    const { currentOffer } = this.props;
    
    if (currentOffer) {
      return {
        offer_id: currentOffer.id,
        allocation_amount: currentOffer.ownership.allocation_amount,
        shareholding: currentOffer.business?.pegate_shareholding || 0,
        shareholdingMain: currentOffer.business?.shareholding || 0,
        offer_currency: currentOffer.business.currency,
        offer_business_id: currentOffer.business.id,
        business_final_amount: currentOffer.ownership.final_amount,
        opportunity_closed: currentOffer.ownership?.closed,
        scope: currentOffer.scope,
        offerUserID: currentOffer.user.id,
        offer_user_firstname: currentOffer.user.first_name,
        offer_user_lastname: currentOffer.user.last_name,
        shares_price: currentOffer.shares_price,
        shares_percent: currentOffer.shares_percent,
        shares_qty: currentOffer.shares_qty,
        internal: currentOffer.internal,
        internal_prize: currentOffer.ownership.internal_prize,
        internal_sales: currentOffer.ownership.internal_sales,
        winners: currentOffer.winners,
        external: currentOffer.external,
        external_prize: currentOffer.ownership.external_prize,
        external_sales: currentOffer.ownership.external_sales,
        offer_step: currentOffer.external?.step,
        success_qty: currentOffer?.success_qty, 
        closed: currentOffer.closed,
        start: currentOffer.start,
        lastBid: currentOffer.lastBid,
        end: currentOffer.end,
        offer_business_name: currentOffer.business.name,
        photo: currentOffer.business.photo ? getMediaFileFromServer(currentOffer.business.photo) : require("../../assets/img/project-img.png")
      };
    }
    return null;
  }
  checkAllModalVisibility = () => {
    const {
      requestExternalModalVisible,
      internalModalVisible,
      externalModalVisible,
      removeOfferModalVisible
    } = this.state
    return requestExternalModalVisible || internalModalVisible || externalModalVisible || removeOfferModalVisible
  }

  getUserLastBids = (id) => {
    if (id && this.props.usersLastBid.length) return this.props.usersLastBid.find((item) => +item.offer.id === +id)
  }
  getWonBid = () => {
    const { match, currentOffer, usersLastBid } = this.props;
    // const isExternal = currentOffer.scope === "EXTERNAL";

    // if (!isExternal && usersLastBid.length) {
    //   if((+usersLastBid[0].offer.id === +match.params.id) && usersLastBid[0].won) {
    //     return {
    //       ...usersLastBid[0],
    //       amount: usersLastBid[0]?.prize ? usersLastBid[0]?.prize * currentOffer.shares_price : usersLastBid[0]?.amount * currentOffer.shares_price,
    //     }
    //   }

    //   return;
    // }
    if (usersLastBid.length) {
      if((+usersLastBid[0].offer.id === +match.params.id) && usersLastBid[0].won) {
        return {
          ...usersLastBid[0],
          amount: usersLastBid[0]?.prize ? usersLastBid[0]?.prize : usersLastBid[0]?.amount,
          scope: usersLastBid[0]?.scope,
        }
      }

      return;
    }

    // if (isExternal && currentOffer?.lastBid) {
    //   const lastBid = currentOffer?.lastBid;

    //   if((+lastBid?.user === +localStorage.getItem("xID")) && lastBid.won) {
    //     return {
    //       ...lastBid,
    //       amount: lastBid?.prize || lastBid?.amount,
    //     }
    //   }

    //   return;
    // }
  }
  getCurrentBusiness = () => {
    const {
      opportunityGroups,
      countries,
      states,
      currentOpenedBusiness,
    } = this.props;

    if (currentOpenedBusiness) {
      const industry = findValueFromDictionary(currentOpenedBusiness.industry, findDictionaryByID(5, opportunityGroups));
      const transaction_status = findValueFromDictionary(currentOpenedBusiness.transaction_status, findDictionaryByID(4, opportunityGroups));
      const country = findValueFromDictionary(currentOpenedBusiness.country, countries);
      const state = findValueFromDictionary(currentOpenedBusiness.state, states);
      const investment_type = findValueFromDictionary(currentOpenedBusiness.investment_type, findDictionaryByID(3, opportunityGroups));

      return {
        id: currentOpenedBusiness.id,
        name: currentOpenedBusiness.name,
        description: currentOpenedBusiness.description,
        industry,
        transaction_status,
        country,
        countryID: currentOpenedBusiness.country,
        state,
        investment_type,
        total_investment_from: currentOpenedBusiness.total_investment_from,
        total_investment_to: currentOpenedBusiness.total_investment_to,
        minimum_investment: currentOpenedBusiness.minimum_investment,
        currency: currentOpenedBusiness.currency,
        shareholding: currentOpenedBusiness.shareholding,
        shareholding_from: currentOpenedBusiness.shareholding_from,
        shareholding_to: currentOpenedBusiness.shareholding_to,
        deadline: currentOpenedBusiness.deadline,
        photo: currentOpenedBusiness.photo ? getMediaFileFromServer(currentOpenedBusiness.photo) : require("../../assets/img/project-img.png"),
        video: currentOpenedBusiness.video ? currentOpenedBusiness.video : null,
        interested: currentOpenedBusiness.interested,
        not_interested: currentOpenedBusiness.not_interested,
        nda_signed: currentOpenedBusiness.nda_signed,
        allow_close: currentOpenedBusiness.allow_close,
        ready_to_start: currentOpenedBusiness.ready_to_start,
        ready_to_commit: currentOpenedBusiness.ready_to_commit,
        allow_commit: currentOpenedBusiness.allow_commit,
        closed: currentOpenedBusiness.closed,
        initial_amount: currentOpenedBusiness.initial_amount,
        allocation_amount: currentOpenedBusiness.allocation_amount,
        internal_cost: currentOpenedBusiness.internal_cost,
        internal_prize: currentOpenedBusiness.internal_prize,
        internal_sales: currentOpenedBusiness.internal_sales,
        internal_income: currentOpenedBusiness.internal_income,
        external_cost: currentOpenedBusiness.external_cost,
        external_prize: currentOpenedBusiness.external_prize,
        external_sales: currentOpenedBusiness.external_sales,
        external_income: currentOpenedBusiness.external_income,
        diligence_amount: currentOpenedBusiness.diligence_amount,
        diligence_costs: currentOpenedBusiness.diligence_costs,
        pegate_fees: currentOpenedBusiness.pegate_fees,
        pegate_involvement: currentOpenedBusiness.pegate_involvement,
        pegate_lead: currentOpenedBusiness.pegate_lead,
        final_amount: currentOpenedBusiness.final_amount,
        real_diligence: currentOpenedBusiness.real_diligence ? currentOpenedBusiness.real_diligence : 0,
        enabled: currentOpenedBusiness.enabled ? currentOpenedBusiness.enabled : 0,
        expired: currentOpenedBusiness.expired ? currentOpenedBusiness.expired : 0,
      };
    }
    return null;
  };

  setInterested = (interestedStatus) => {
    const { setInterested } = this.props;
    const businessID = this.props.match.params.id;
    setInterested(businessID, interestedStatus);
  };

  toggleIsInterested = (isInterested) => {
    const { userInfo, currentOpenedBusiness } = this.props;

    if (userInfo && parseInt(userInfo.onboarded) === 1) {
      if (isInterested) {
        this.setState({
          popupNdaVisible: true
        }, () => {
          window.scroll(0, 0);

          if(!currentOpenedBusiness.interested) {
            this.setInterested(1);
          }
        })
      } else {
        this.setInterested(0);
      }
    } else {
      this.toggleOnboardedMessageModal(true);
    }
  };

  signNdaOnClick = () => {
    const { form, match, signNDA, currentOffer } = this.props;
    let businessID = match.params.id;

    if(currentOffer) {
      businessID = currentOffer?.business?.id;
    }

    form.validateFields(['signature'])
      .then((values) => {
        signNDA(businessID, values.signature);
      });
  };

  hideNdaPopUp = () => {
    this.setState({ popupNdaVisible: false });
  };

  hideBidPopUp = () => {
    this.setState({ bidPopupVisible: false });
  };

  handleNdaCheckboxOnChange = (event) => {
    this.setState({
      isNdaCheckboxChecked: event.target.checked
    })
  };

  getNDAOnClick = () => {
    const { getNDA } = this.props;
    getNDA();
  };

  readyToCommitOnClick = () => {
    const { readyToCommit, match } = this.props;
    const businessID = match.params.id;

    readyToCommit(businessID);
  };

  readyToCloseOnClick = () => {
    const { readyToClose, match } = this.props;
    const businessID = match.params.id;

    readyToClose(businessID);
  };

  startTheDealOnClick = () => {
    this.setState({
      amountPopupVisible: true,
    }, () => {
      window.scroll(0, 0);
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { form } = this.props;
    const currentBusiness = this.getCurrentBusiness();
    const { minimum_investment, currency } = currentBusiness ? currentBusiness : "";

    const inputtedNumberRangeStatus = (input) => {
      const inputNumber = parseInt(input);

      if (parseInt(minimum_investment) > inputNumber) {
        return false;
      }
      return true;
    };

    form.validateFields(['amount'])
      .then((values) => {
        const { amount } = values;
        if (inputtedNumberRangeStatus(amount)) {
          const { readyToStart, match } = this.props;
          const business = match.params.id;

          readyToStart(business, amount);
        } else {
          form.setFields({
            amount: {
              value: amount,
              errors: [new Error(`The invest must be ${formatNumber(minimum_investment)} ${currency} or greater`)]
            }
          });
        }
      });
  };

  toggleModal = (status) => {
    this.setState({
      popupVisible: status,
    }, () => {
      if (status) {
        window.scroll(0, 0);
      }
    });
  };

  toggleModalSuccess = (status) => {
    this.setState({ popupSuccessVisible: status });
  };

  toggleAmountPopup = (status) => {
    this.setState({ amountPopupVisible: status });
  };

  toggleVideoModal = (status, videoSrc = null) => {
    this.setState({
      videoSrc,
      popupVideoVisible: status,
    }, () => {
      if (status) {
        window.scroll(0, 0);
      }
    });
  };

  toggleOnboardedMessageModal = (status) => {
    this.setState({
      nonOnboardedMessagePopupVisible: status,
    }, () => {
      if (status) {
        window.scroll(0, 0);
      }
    })
  };

  toggleInvestmentActionMessageModal = (type, status) => {
    this.setState({ investmentActionMessagePopupInfo: { type, visible: status } });
  };

  isAdmin = () => {
    const { role } = this.props;
    return role === "ADMIN";
  };

  printWatchVideoButton = () => {
    const currentBusiness = this.getCurrentBusiness();
    const { video } = currentBusiness ? currentBusiness : "";
    const buttonProps = {
      title: "Watch Video",
      size: "lg",
      theme: "lightblue",
      style: { padding: '0 18px' },
      mode: "bordered",
      className: "radius-8 shrink",
    };

    if (validURL(video)) {
      buttonProps.href = video;
      buttonProps.target = '_blank';
    } else {
      buttonProps.onClick = () => this.toggleVideoModal(true, video);
    }

    return (
      video
        ? (
          <Button {...buttonProps} />
        ) : (
          <Button
            {...buttonProps}
            type="button"
            disabled={true}
          />
        )
    );
  };

  printDataRoomButton = () => {
    const { match, userInfo } = this.props;
    const businessID = match.params.id;
    const currentBusiness = this.getCurrentBusiness();
    const { name, nda_signed } = currentBusiness ? currentBusiness : "";
    const buttonProps = {
      title: <b>View</b>,
      suffix: (
        <Icon
          type="key-right"
          size={14}
          style={{ marginLeft: 15, fontWeight: 'bold' }}
        />
      ),
      size: "lg",
      theme: "lightblue",
      style: { padding: '0 18px' },
      className: "btn-bordered radius-8",
    };

    if (nda_signed && userInfo && parseInt(userInfo.access_files) === 1) {
      return (
        <Button
          {...buttonProps}
          onClick={() => this.viewDataRoomOnClick(`/data-room/0/${name}/true/${businessID}`, name)}
        />
      )
    }

    return (
      <Button
        {...buttonProps}
        type="button"
        disabled={true}
      />
    )
  };

  printMyInvestmentsButton = () => {
    const {
      userInfo,
      setActivePortfolioTabKey,
      currentOpenedBusiness,
      setActiveInvestmentId,
    } = this.props;

    const buttonProps = {
      title: <b>View</b>,
      size: "lg",
      theme: "lightblue",
      style: { padding: '0 18px' },
      className: "btn-bordered radius-8",
      suffix: (
        <Icon
          type="key-right"
          size={14}
          style={{ marginLeft: 15, fontWeight: 'bold' }}
        />
      ),
    };

    if (userInfo && parseInt(userInfo.access_portfolio) === 1 && currentOpenedBusiness && currentOpenedBusiness.closed) {
      return (
        <Button
          {...buttonProps}
          onClick={() => {
            setActivePortfolioTabKey('analytics');
            setActiveInvestmentId(currentOpenedBusiness.id);
            history.push('/portfolio');
          }}
        />
      )
    }

    return (
      <Button
        {...buttonProps}
        type="button"
        disabled={true}
      />
    )
  };

  handleRemoveOnClick = (offerId) => {
    Modal.confirm({
      title: "Are you sure you want to close offer?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        const { closeOffer } = this.props;

        closeOffer({id: offerId});
      }
    });
    
  }

  getInterestedActionStylesViaBusinessStatus = () => {
    const { currentOpenedBusiness } = this.props;
    if (currentOpenedBusiness) {
      if (currentOpenedBusiness.interested) {
        return {
          no: {
            theme: 'lightblue',
            mode: 'bordered',
            className: 'radius-8 fluid-x',
          },
          yes: {
            theme: 'blue',
            mode: '',
            className: 'btn-shadow radius-8 fluid-x',
          },
        };
      }
      if (currentOpenedBusiness.not_interested) {
        return {
          no: {
            theme: 'blue',
            mode: '',
            className: 'btn-shadow radius-8 fluid-x',
          },
          yes: {
            theme: 'lightblue',
            mode: 'bordered',
            className: 'radius-8 fluid-x',
          },
        };
      }
    }
    return {
      no: {
        theme: 'lightblue',
        mode: 'bordered',
        className: 'radius-8 fluid-x',
      },
      yes: {
        theme: 'lightblue',
        mode: 'bordered',
        className: 'radius-8 fluid-x',
      },
    };
  };

  isProgressStepActiveData = (step) => {
    const currentBusiness = this.getCurrentBusiness();
    const {
      nda_signed,
      initial_amount,
      allocation_amount,
      diligence_amount,
      ready_to_commit,
      closed,
    } = currentBusiness ? currentBusiness : '';

    switch (step) {
      case 1:
        return {
          active: !!nda_signed,
          tooltip: 'NDA Signed',
        };
      case 2:
        return {
          active: !!initial_amount,
          tooltip: 'Committed the initial amount',
        };
      case 3:
        return {
          active: allocation_amount || diligence_amount,
          tooltip: 'Pe-Gate suggestion',
        };
      case 4:
        return {
          active: !!ready_to_commit,
          tooltip: 'Committed to Pe-Gate suggestion',
        };
      case 5:
        return {
          active: !!closed,
          tooltip: 'Deal is closed',
        };
      default:
        return false;
    }
  };

  viewDataRoomOnClick = (path, name) => {
    const routes = [
      {
        path: '/data-room',
        breadcrumbName: 'Data Room',
      },
      {
        path,
        breadcrumbName: name,
      }
    ];

    localStorage.setItem('routes', JSON.stringify(routes));
    history.push(path);
  };

  signatureBtnClearOnClick = () => {
    this.setState({
      isNdaCheckboxChecked: false,
    }, () => {
      this.refs.signature.clear();
    });
  };

  setNotInterestedTarget = (target) => {
    this.notInterestedTarget = target;
    this.setInterested(0);
  };

  renderOpportunityActionButtons = () => {
    const { loading, involveRequest, acceptInvolveRequest, rejectInvolveRequest, match } = this.props;
    const businessID = match.params.id;
    const currentBusiness = this.getCurrentBusiness();
    const {
      minimum_investment, currency,
      interested, nda_signed, ready_to_start, ready_to_commit,
      closed, allow_commit, not_interested,
    } = currentBusiness ? currentBusiness : "";
    const noBtnStyles = this.getInterestedActionStylesViaBusinessStatus().no;
    const yesBtnStyles = this.getInterestedActionStylesViaBusinessStatus().yes;

    const NotInterestedButton = () => (
      <div className="medium-flex-container align-middle align-center project_actions__buttons not-interested-button-wrapper" >
        <Button
          title="I'm Not Interested"
          size="xl"
          theme="lightblue"
          mode="bordered"
          className="radius-8 fluid-x"
          type="button"
          loading={handleLoading(loading, "SetInterested") && this.notInterestedTarget === 'not_interested'}
          onClick={() => this.setNotInterestedTarget('not_interested')}
        />
      </div>
    );

    if (closed) {
      return (
        <div className="medium-flex-container align-middle align-center project_actions__buttons">
          <Button
            title="Thank you for investing"
            size="xl"
            theme="blue"
            mode="bordered"
            className="radius-8 fluid-x"
            type="button"
            disabled
          />
        </div>
      );
    }
    if (!ready_to_start && interested && nda_signed) {
      return (
        <>
          <div className="medium-flex-container align-middle align-center project_actions__buttons">
            <Button
              title="Make a non-binding commitment"
              size="xl"
              theme="blue"
              mode="bordered"
              className="radius-8 fluid-x"
              type="button"
              onClick={this.startTheDealOnClick}
              disabled={this.isOpportunityExpired()}
            />
          </div>
          <NotInterestedButton />
        </>
      );
    }
    if (!allow_commit && nda_signed && (interested || not_interested)) {
      return (
        <>
          <div className="medium-flex-container align-middle align-center project_actions__buttons">
            <Button
              title="Change Your Commitment"
              size="xl"
              theme="blue"
              mode="bordered"
              className="radius-8 fluid-x"
              type="button"
              onClick={this.startTheDealOnClick}
              disabled={this.isOpportunityExpired()}
            />
          </div>
          <NotInterestedButton />
        </>
      );
    }
    if (ready_to_start && !interested) {
      return (
        <div className="medium-flex-container align-middle align-center project_actions__buttons">
          <Button
            title="Yes"
            size="xl"
            theme="lightblue"
            mode="bordered"
            className="radius-8 fluid-x"
            type="button"
            onClick={this.readyToCommitOnClick}
            loading={handleLoading(loading, "ReadyToCommit")}
            disabled={this.isOpportunityExpired()}
          />
          <Button
            title="No"
            size="xl"
            theme="lightblue"
            mode="bordered"
            className="radius-8 fluid-x"
            type="button"
            disabled
          />
        </div>
      );
    }
    if (allow_commit && nda_signed && !ready_to_commit) {
      return (
        <>
          <div className="medium-flex-container align-middle align-center project_actions__buttons">
            <Button
              title="Yes"
              size="xl"
              theme="lightblue"
              mode="bordered"
              className="radius-8 fluid-x"
              type="button"
              onClick={this.readyToCommitOnClick}
              loading={handleLoading(loading, "ReadyToCommit")}
              disabled={this.isOpportunityExpired()}
            />
            <Button
              title="No"
              size="xl"
              theme="lightblue"
              mode="bordered"
              className="radius-8 fluid-x"
              type="button"
              onClick={() => this.setNotInterestedTarget('no')}
              loading={handleLoading(loading, "SetInterested") && this.notInterestedTarget === 'no'}
              disabled={this.isOpportunityExpired()}
            />
          </div>
          <NotInterestedButton />
        </>
      )
    }
    if (ready_to_start && interested) {
      return (
        <div className="medium-flex-container align-middle align-center project_actions__buttons">
          <Button
            title="Yes"
            size="xl"
            theme="blue"
            mode="bordered"
            className="radius-8 fluid-x"
            type="button"
            disabled
          />
          <Button
            title="No"
            size="xl"
            theme="lightblue"
            mode="bordered"
            className="radius-8 fluid-x"
            type="button"
            disabled
          />
        </div>
      );
    }
    if ((!interested || !nda_signed) && !involveRequest) {
      return (
        <>
          <div className="medium-flex-container align-middle align-center project_actions__buttons">
            <Button
              title="Yes"
              size="xl"
              theme={yesBtnStyles.theme}
              mode={yesBtnStyles.mode}
              className={yesBtnStyles.className}
              type="button"
              onClick={() => this.toggleIsInterested(true)}
              disabled={this.isOpportunityExpired()}
            />
            <Button
              title="No"
              size="xl"
              theme={noBtnStyles.theme}
              mode={noBtnStyles.mode}
              className={noBtnStyles.className}
              type="button"
              onClick={() => this.toggleIsInterested(false)}
              loading={handleLoading(loading, "SetInterested")}
              disabled={this.isOpportunityExpired()}
            />
          </div>
          <div className="text font-medium color-grey-900 minimum-investment-text-wrapper project_actions__hint">
            Minimum Investment: {formatNumber(minimum_investment)} {currency}
          </div>
        </>
      );
    }
    if(involveRequest) {
      return (
        <>
          <div className="medium-flex-container align-middle align-center project_actions__buttons">
            <Button
              title="Accept"
              size="xl"
              theme={yesBtnStyles.theme}
              mode={yesBtnStyles.mode}
              className={yesBtnStyles.className}
              type="button"
              onClick={() => acceptInvolveRequest({ business: businessID })}
              loading={handleLoading(loading, "AcceptInvolveRequest")}
            />
            <Button
              title="Reject"
              size="xl"
              theme={noBtnStyles.theme}
              mode={noBtnStyles.mode}
              className={noBtnStyles.className}
              type="button"
              onClick={() => rejectInvolveRequest({ business: businessID })}
              loading={handleLoading(loading, "RejectInvolveRequest")}
            />
          </div>
        </>
      );
    }
  };

  isOnboardingFormFilled = () => {
    const { onboardingData } = this.props;
    return onboardingData && onboardingData.other_person_interest != null && onboardingData.other_person_interest !== "";
  };

  toggleBusinessVisibility = () => {
    const { disableBusiness, enableBusiness } = this.props;
    const currentBusiness = this.getCurrentBusiness();
    const { id, enabled } = currentBusiness ? currentBusiness : "";

    if (parseInt(enabled) === 1) {
      disableBusiness(id);
    } else {
      enableBusiness(id);
    }
  };

  isOpportunityExpired = () => {
    const currentBusiness = this.getCurrentBusiness();
    const { expired } = currentBusiness ? currentBusiness : "";

    return parseInt(expired) === 1;
  };

  setVideoStreamURL = async (videoInputCode) => {
    const { xID, xTOKEN } = this.props;
    const config = { id: xID, token: xTOKEN };

    await CommonUtils.apiCall("get", "public", `shared/${videoInputCode}?stream`, {}, config)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            videoStreamURL: `${SERVER_URL}/${response.data.success.path}`,
          });
        }
      });
  };

  changePopUpView = (key) => {
    this.setState({ [key]: !this.state[key] });
  }
  submitInternalPublish = (values) => {
    this.props.internalPublish({ offer: this.props.currentOffer.id, ...values });
  };
  submitExternalPublish = (values) => {
    this.props.externalPublish({ offer: this.props.currentOffer.id, ...values })
  }
  requestExternalPublish = (values) => {
    this.props.requestExternalSales({ offer: this.props.currentOffer.id, ...values })
  }

  deleteOffer = (values) => {
    const { removeOfferInvestor, match } = this.props;
    const deletedOfferData = { id: match.params.id, reason: values.reason };
    if (this.isAdmin()) this.setState({ removeOfferModalVisible: true })
    else removeOfferInvestor(deletedOfferData)
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      loading, businessLoading, errorMessage, businessErrorMessage, currentOffer,
      xID, history, getReport, getBusinessSummary, getBusiness, match, resetInvolveRequestData
    } = this.props;
    // const loadingType = loading.type;
    // const loadingState = loading.state;
    // const errorText = errorMessage.message;
    const businessID = match.params.id;
    const businessLoadingType = businessLoading.type;
    const businessLoadingState = businessLoading.state;
    const businessErrorText = businessErrorMessage.message;

    if ((currentOffer?.closed || (+xID === +this.props?.currentOffer?.user?.id) || !compareTimes(this.props?.currentOffer?.end)) && window.bidsTimeout) {
      clearInterval(window.bidsTimeout)
    }
    if (!this.state.offerLeftTime && currentOffer?.end) {
      this.setState({ offerLeftTime: calculateTime(currentOffer?.end) })
    }

    if (prevState.videoSrc !== this.state.videoSrc && this.state.videoSrc) {
      this.setVideoStreamURL(this.state.videoSrc);
    }

    if (!businessErrorText && !businessLoadingState && prevProps.businessLoading.state) {
      switch (businessLoadingType) {
        case "SignNDA":
          this.setState((state, props) => {
            if(props.currentOffer) {
              return {
                popupNdaVisible: false,
              }
            }
            return {
              popupNdaVisible: false,
              popupSuccessVisible: true
            }
          });
          break;
        case "ReadyToStart":
          this.setState({ amountPopupVisible: false });
          break;
        case "ReadyToCommit":
          this.toggleModal(true);
          break;
        default:
      }
    }

    if (handleErrorMessage(errorMessage, prevProps.errorMessage, "ReadyToCommit")) {
      this.toggleInvestmentActionMessageModal('commit', true);
    }

    if (handleErrorMessage(errorMessage, prevProps.errorMessage, "ReadyToClose")) {
      this.toggleInvestmentActionMessageModal('close', true);
    }

    if (handleErrorMessage(errorMessage, prevProps.errorMessage, "ReadyToStart")) {
      this.toggleInvestmentActionMessageModal('start', true);
    }
    if (
      handleErrorMessage(errorMessage, prevProps.errorMessage, "InternalPublish") ||
      handleErrorMessage(errorMessage, prevProps.errorMessage, "ExternalPublish") ||
      handleErrorMessage(errorMessage, prevProps.errorMessage, "AddBid") ||
      handleErrorMessage(errorMessage, prevProps.errorMessage, "RemoveOfferInvestor") ||
      handleErrorMessage(errorMessage, prevProps.errorMessage, "RemoveOfferAdmin")
    ) {
      message.error(errorMessage.message);
    }
    if (handleErrorMessage(errorMessage, prevProps.errorMessage, "GetOffer")) {
      message.error(errorMessage.message);
      history.push("/offers")
    }
    if (handleOperationSuccess(errorMessage, loading, prevProps.loading.state, 'ExternalSale')) {
      this.changePopUpView('requestExternalModalVisible');
    }
    if (handleOperationSuccess(errorMessage, loading, prevProps.loading.state, 'InternalPublish')) {
      this.changePopUpView('internalModalVisible');
      this.setState({ internalPublishSuccess: true });
      message.success("Offer successfully published");
    }
    if (handleOperationSuccess(errorMessage, loading, prevProps.loading.state, 'ExternalPublish')) {
      this.changePopUpView('externalModalVisible');
      this.setState({ externalPublishSuccess: true });
      message.success("Offer successfully published");
    }
    if (handleOperationSuccess(errorMessage, loading, prevProps.loading.state, 'CloseOffer')) {
      this.setState({ closeOfferSuccess: true });
      message.success("Offer successfully closed");
    }
    if (handleOperationSuccess(errorMessage, loading, prevProps.loading.state, "ExternalSale")) {
      this.changePopUpView('submitModalVisible');
    }
    if (handleOperationSuccess(errorMessage, loading, prevProps.loading.state, 'RemoveOfferInvestor') ||
      handleOperationSuccess(errorMessage, loading, prevProps.loading.state, 'RemoveOfferAdmin')
    ) {
      message.success("Offer successfully rejected.");
      history.push("/offers")
    }
    if (handleOperationSuccess(errorMessage, loading, prevProps.loading.state, "ExternalSale")) {
      this.changePopUpView('submitModalVisible');
    }
    if (handleOperationSuccess(businessErrorMessage, businessLoading, prevProps.loading.state, "AcceptInvolveRequest")) {
      getBusiness(businessID);
    }
    if (handleOperationSuccess(businessErrorMessage, businessLoading, prevProps.loading.state, "RejectInvolveRequest")) {
      getBusiness(businessID);
      resetInvolveRequestData();
    }
    if (currentOffer?.business?.id && !this.getOfferRef.current) {
      this.getOfferRef.current = true
      getBusinessSummary(currentOffer.business.id);
      getBusiness(currentOffer?.business?.id);
      getReport(currentOffer.user.id, currentOffer?.business.currency, currentOffer.business.id)
    }
  }

  offerCalls = () => {
    const { match, getOffer, searchBids, getOfferBidRequest } = this.props;
    const businessID = match.params.id;

    this.setState({ offerLeftTime: null })
    getOffer({ id: businessID });
    searchBids({ offer: businessID });
    getOfferBidRequest({ offer: businessID });
  }

  offerCallsTimeout = () => {
    window.bidsTimeout = setInterval(() => {
      this.offerCalls();
      this.setState({ offerLeftTime: null })
    }, BID_REFRESH_TIME * 1000)
  }

  confirmBidOnClick = () => {
    const { bidAmount, } = this.state;
    const { match, addExternalBid, addInternalBid, form } = this.props;
    const businessID = match.params.id;
    const currentOffer = this.getCurrentOffer();
    
    form.validateFields(['bid_signature'])
    .then(() => {
      if(currentOffer?.scope === 'EXTERNAL') {
        addExternalBid({ offer: businessID, amount: bidAmount });
      } else {
        addInternalBid({ offer: businessID, amount: bidAmount });
      }
    });
  }

  componentWillUnmount() {
    const { removeCurrentBusiness, clearCurrentOffer, location, resetInvolveRequestData, resetOfferBidRequest } = this.props;
    if (location.pathname.includes('offer')) clearCurrentOffer()
    else removeCurrentBusiness();
    clearInterval(window.bidsTimeout);
    resetInvolveRequestData();
    resetOfferBidRequest();
  }

  updateSize = (e) => {
    if ((e.target.innerWidth < 600 || e.target.innerWidth > 1200) && this.state.antSteps === 'horizontal') {
       this.setState({ antSteps: 'vertical' });
    }
    else if (e.target.innerWidth > 600 && e.target.innerWidth < 1200 && this.state.antSteps === 'vertical') {
      this.setState({ antSteps: 'horizontal' });
   }
  }

  componentDidMount() {
    const { getBusiness, getBusinessInvolveRequest, match, location, searchOfferBidsCount } = this.props;
    const businessID = match.params.id;
    if (location.pathname.includes('offer')) {
      this.offerCalls();
      this.setState({ isAuction: true });
      if (!this.isAdmin()) this.offerCallsTimeout();
      if(this.isAdmin()) {
        searchOfferBidsCount({ offer: businessID });
      }

    } else {
      getBusiness(businessID);
      getBusinessInvolveRequest(businessID);
    }
    window.addEventListener('resize', this.updateSize);
  }

  getCalcFieldData = () => {
    const { businessSummary, businessReports } = this.props;
    const currentOffer = this.getCurrentOffer();
    return ({
      totalReceived: businessSummary?.final_amount,
      finalAmount: ((currentOffer?.business_final_amount + currentOffer?.external_prize + currentOffer?.internal_prize) - currentOffer?.internal_sales) - currentOffer?.external_sales,
      shareholding: currentOffer?.shareholding,
      shareholdingMain: currentOffer?.shareholdingMain || 0,
      originalSharePrice: 1,
      businessReports: businessReports,
      businessClosedDate: currentOffer?.opportunity_closed,
      sharesQty: currentOffer?.shares_qty,
      price: currentOffer?.shares_price,
    });
  };

  offerProgressStep = (step) => {
    const currentOffer = this.getCurrentOffer();
    const {
      end,
      start,
      internal,
      external,
      closed,
    } = currentOffer ? currentOffer : '';
    console.log(closed);
    switch (step) {
      case 1:
        return {
          active: (internal?.requested && !external?.requested && (compareTimes(end) || !end)) || external?.requested || !!closed,
          tooltip: 'Submit Form',
        };
      case 2:
        return {
          active: (internal?.approved && !external?.requested && compareTimes(end)) || external?.approved || !!closed,
          tooltip: 'PE Gate: Review and Approve',
        };
      case 3:
        return {
          active: (!compareTimes(start) && ((internal?.approved && !external?.requested && compareTimes(end)) || external?.approved)) || !!closed,
          tooltip: 'Listing Live',
        };
      case 4:
        return {
          active: !!closed,
          tooltip: 'Auction Closed',
        };
      default:
        return false;
    }
  }

  
  offerProgressStep1 = (step) => {
    const currentOffer = this.getCurrentOffer();
    const {
      end,
      start,
      internal,
      external,
      closed,
    } = currentOffer ? currentOffer : '';
      var r = -1;
      if ((internal?.requested && !external?.requested && (compareTimes(end) || !end)) || external?.requested || !!closed){
        // return {
        //   active: 1,
        //   tooltip: 'Submit Form',
        // };
        r++;
      }
      if ((internal?.approved && !external?.requested && compareTimes(end)) || external?.approved || !!closed) {
        // return {
        //   active: 2,
        //   tooltip: 'PE Gate: Review and Approve',
        // };
        r++;
      }
      if ((!compareTimes(start) && ((internal?.approved && !external?.requested && compareTimes(end)) || external?.approved)) || !!closed) {
        // return {
        //   active: 3,
        //   tooltip: 'Listing Live',
        // };
        r++;
      }
      if(!!closed){
        r+=2
        // return {
        //   active: 4,
        //   tooltip: 'Auction Closed',
        // };
      }
      return r;
  }

  render() {
    const {
      offerLeftTime,
      popupNdaVisible,
      bidPopupVisible,
      popupVisible,
      popupSuccessVisible,
      popupVideoVisible,
      videoStreamURL,
      isNdaCheckboxChecked,
      amountPopupVisible,
      submissionDetailsVisible,
      nonOnboardedMessagePopupVisible,
      investmentActionMessagePopupInfo,
      internalModalVisible,
      externalModalVisible,
      removeOfferModalVisible,
      requestExternalModalVisible,
      internalPublishSuccess,
      externalPublishSuccess,
      closeOfferSuccess,
      bidAmount,
    } = this.state;

    const { form, match, loading, businessLoading, userInfo, xID, removeOfferAdmin, involveRequest, offerBidRequest, offerBidsCount } = this.props;
    const { getFieldDecorator } = form;
    const firstName = userInfo ? userInfo.first_name : "";

    const writeMessages = userInfo ? parseInt(userInfo.write_messages) === 1 : false;

    const currentBusiness = this.getCurrentBusiness();
    const currentOffer = this.getCurrentOffer();
    const businessID = match.params.id;
    const lastBidInfo = this.getUserLastBids(businessID);
    const fieldsData = this.getCalcFieldData();
    calcFieldValues = fieldsData ? calcFieldsValue(fieldsData) : "";


    const { photo } = currentBusiness || currentOffer || "";

    const {
      id, indexOfArray, name, description, industry,
      transaction_status, country, state,
      investment_type, total_investment_from,
      total_investment_to, minimum_investment, currency,
      shareholding, shareholding_from, shareholding_to, deadline,
      interested, nda_signed, ready_to_start,
      closed, not_interested, initial_amount, allocation_amount,
      diligence_amount, diligence_costs, pegate_fees, pegate_involvement,
      pegate_lead, final_amount, real_diligence, enabled, internal_sales, internal_income,
      internal_cost, internal_prize, external_cost, external_prize, external_income, external_sales,
    } = currentBusiness ? currentBusiness : "";

    const {
      offer_business_name, shares_price, offerUserID, offer_user_firstname, offer_user_lastname, business_final_amount,
      scope, offer_step, internal, external, lastBid, shares_qty, success_qty, start, end, shares_percent,
      closed: offer_closed, team, offer_currency, end: offer_end_date, offer_business_id, winners
    } = currentOffer ? currentOffer : "";

    const primaryReceived = !closed ? allocation_amount : final_amount;
    const totalReceived = ((primaryReceived + internal_cost + external_cost) - internal_income) - external_income;
    const totalSharesQuantity = ((primaryReceived + internal_prize + external_prize) - internal_sales) - external_sales;

    const rightSideDataProps = () => {
      // if (!this.state.isAuction) {
        return {
          industry, transaction_status, country, state, isAdmin: this.isAdmin,
          internal_cost, external_cost, offerUserID, totalReceived, totalSharesQuantity,
          investment_type, total_investment_from, total_investment_to, currency,
          minimum_investment, shareholding, shareholding_from, shareholding_to, diligence_costs,
          pegate_fees, pegate_involvement, pegate_lead, deadline, offer_user_firstname, offer_user_lastname
        };
      // }
      // return {
      //   shares_price, offerUserID, scope, team, isAdmin: this.isAdmin, internal, external,
      //   offer_user_firstname, offer_user_lastname, currency: offer_currency, sharesPrice: shares_price,
      //   getCalcFieldData: this.getCalcFieldData, userID: xID
      // };
    };

    const returnSoldSharesAmount = () => {
      if(internal?.stats?.amount > shares_qty) {
        return shares_qty;
      }

      return internal?.stats?.amount;
    }

    const getBidsButton = () => (
      <div className="bg-white radius-10 flex-container align-middle align-justify project_direct_card">
        <div className="flex-container align-middle project_direct_card__title">
          <Icon type="portfolio" size={32} style={{ marginRight: 16 }} className="color-grey" />
          <div className="text text-18 font-heavy color-grey">
            Bids
          </div>
        </div>
        <Button
          title="View"
          size="lg"
          theme="lightblue"
          mode="bordered"
          type="button"
          style={{ padding: '0 18px' }}
          className="btn-bordered radius-8"
          suffix={
            <Icon
              type="key-right"
              size={14}
              style={{ marginLeft: 15, fontWeight: 'bold' }}
            />
          }
          onClick={() => history.push(`/bids/${businessID}`)
          }
        />
      </div>
    )

    return (
      <div className="relative fluid-y screen_wrapper">
        <div className="row">
          <div className="column small-12">
            <div className="page_top page_top-tall">
              <div className="flex-container align-middle">
                <Button
                  title={<Icon type="key-left" size={16} />}
                  size="md"
                  theme="lightblue"
                  className="radius-10 back_btn"
                  onClick={() => goBack()}
                />
                <div className="page_title visible-first custom-headline text text-28 text-height-48 font-heavy font-uppercase color-grey">
                  <h1>{name || offer_business_name}</h1>
                </div>
                {
                  indexOfArray === 0 &&
                  <div className="bg-white radius-4 text text-10 font-medium color-grey font-uppercase font-spacing-02 shadow-tag c-tag">new</div>
                }
              </div>
            </div>
          </div>
        </div>
        {!this.checkAllModalVisibility() ? <div className="row">
          <div className="column small-12 large-5">
            <div className="project_img">
              <img src={photo} width={572} height={364} className="radius-10 fluid-x" alt="" />
            </div>
            {
              this.props.location.pathname.includes('offer') && ((+xID === +offerUserID) || this.isAdmin()) ? (
                <>
                  <div className="flex-container align-middle align-justify progress_steps">
                    <Steps size="small" current={this.offerProgressStep1()} labelPlacement="vertical" direction={this.state.antSteps} >
                      <Step title="Submit Form" />
                      <Step title="PE Gate: Review and Approve" />
                      <Step title="Listing Live" />
                      <Step title="Auction Closed" />
                    </Steps>
                    {/* {
                      new Array(4).fill(null).map((item, index) => (
                        <ProgressStep
                          key={index}
                          className={`flex-child-auto ${this.offerProgressStep(index + 1).active ? 'active' : ''}`}
                          tooltip={this.offerProgressStep(index + 1).tooltip}
                        />
                      ))
                    } */}
                  </div>
                  {
                    !this.isAdmin()
                    ? <div className="project_directs mb_30">
                        {getBidsButton()}
                      </div>
                      : null
                  }
                </>
                 
              ) : null
            }

            {
              this.isAdmin()
                ? (
                  <div className="project_directs mb_30">
                    <div className="bg-white radius-10 flex-container align-middle align-justify project_direct_card">
                      <div className="flex-container align-middle project_direct_card__title">
                        <Icon type="statuses" size={32} style={{ marginRight: 16 }} className="color-grey" />
                        <div className="text text-18 font-heavy color-grey">
                          User Status
                        </div>
                      </div>
                      <Button
                        title={<b>View</b>}
                        suffix={<Icon type="key-right" size={14} style={{ marginLeft: 15, fontWeight: 'bold' }} />}
                        size="lg" theme="lightblue" style={{ padding: '0 18px' }}
                        className="btn-bordered radius-8"
                        linkTo={`/user-statuses/${id || offer_business_id}`}
                      />
                    </div>
                    
                      <div className="bg-white radius-10 flex-container align-middle align-justify project_direct_card">
                        <div className="flex-container align-middle project_direct_card__title">
                          <Icon type="folders" size={32} style={{ marginRight: 16 }} className="color-grey" />
                          <div className="text text-18 font-heavy color-grey">
                            Data Room
                          </div>
                        </div>
                        <Button
                          title={<b>View</b>}
                          suffix={
                            <Icon
                              type="key-right"
                              size={14}
                              style={{ marginLeft: 15, fontWeight: 'bold' }}
                            />
                          }
                          size="lg"
                          theme="lightblue"
                          style={{ padding: '0 18px' }}
                          className="btn-bordered radius-8"
                          onClick={() => this.viewDataRoomOnClick(`/data-room/0/${name}/true/${businessID}`, name)}
                        />
                      </div>
                      {!this.props.location.pathname.includes('offer') && <>
                        <div className="bg-white radius-10 flex-container align-middle align-justify project_direct_card">
                          <div className="flex-container align-middle project_direct_card__title">
                            <Icon type="charts" size={32} style={{ marginRight: 16 }} className="color-grey" />
                            <div className="text text-18 font-heavy color-grey">
                              Statistics
                            </div>
                          </div>
                          <Button
                            title={<b>View</b>}
                            suffix={
                              <Icon
                                type="key-right"
                                size={14}
                                style={{ marginLeft: 15, fontWeight: 'bold' }}
                              />
                            }
                            size="lg"
                            theme="lightblue"
                            style={{ padding: '0 18px' }}
                            className="btn-bordered radius-8"
                            linkTo={`/admin-statistics/${id}/${name}/${currency}`}
                          />
                        </div>

                        <div className="radius-10 bg-white medium-flex-container align-middle align-justify project_direct_card">
                          <div className="flex-container align-middle project_direct_card__title">
                            <Icon type="video" size={32} style={{ marginRight: 14 }} className="color-grey" />
                            <div className="text text-18 font-heavy color-grey">
                              Video about opportunity
                            </div>
                          </div>
                          {this.printWatchVideoButton()}
                        </div>
                      </>}
                    {
                      this.state.isAuction ? (
                        <>
                          {getBidsButton()}
                          <div className="bg-white radius-10 flex-container align-middle align-justify project_direct_card">
                            <div className="flex-container align-middle project_direct_card__title">
                              <Icon type="file" size={32} style={{ marginRight: 16 }} className="color-grey" />
                              <div className="text text-18 font-heavy color-grey">
                                Submission Details
                              </div>
                            </div>
                            <Button
                              title={<b>View</b>}
                              suffix={
                                <Icon
                                  type="key-right"
                                  size={14}
                                  style={{ marginLeft: 15, fontWeight: 'bold' }}
                                />
                              }
                              size="lg"
                              theme="lightblue"
                              style={{ padding: '0 18px' }}
                              className="btn-bordered radius-8"
                              onClick={() => this.setState({ submissionDetailsVisible: true })}
                            />
                          </div>
                          <div className="radius-10 bg-white medium-flex-container medium-up-1 align-middle align-justify project_direct_card">
                            <div className="medium-flex-container align-middle align-center project_actions__buttons not-interested-button-wrapper small-12" >
                              <Button
                                title="Publish for Co-Shareholders"
                                size="xl"
                                theme="lightblue"
                                mode="bordered"
                                className="radius-8 fluid-x"
                                type="button"
                                disabled={(!internal?.requested || internal?.approved) || internalPublishSuccess}
                                onClick={() => this.changePopUpView("internalModalVisible")}
                              />
                            </div>
                            <div className="medium-flex-container align-middle align-center project_actions__buttons not-interested-button-wrapper small-12" >
                              <Button
                                title="Publish for All investors"
                                size="xl"
                                theme="lightblue"
                                mode="bordered"
                                className="radius-8 fluid-x"
                                type="button"
                                disabled={offer_closed || (!external?.requested || external?.approved) || externalPublishSuccess || closeOfferSuccess}
                                onClick={() => this.changePopUpView("externalModalVisible")}
                              />
                            </div>
                            <div className="medium-flex-container align-middle align-center project_actions__buttons not-interested-button-wrapper small-12" >
                              <Button
                                title="Close Offer"
                                size="xl"
                                theme="lightblue"
                                mode="bordered"
                                className="radius-8 fluid-x"
                                type="button"
                                disabled={offer_closed || (!external?.requested || external?.approved) || closeOfferSuccess || externalPublishSuccess}
                                onClick={() => this.handleRemoveOnClick(businessID)}
                              />
                            </div>
                            <div className="medium-flex-container align-middle align-center project_actions__buttons not-interested-button-wrapper small-12" >
                              {
                                this.isAdmin() && !this.state.isAuction
                                  ? null
                                  : this.state.isAuction && (this.isAdmin() || (+xID === +offerUserID)) && (offer_closed !== undefined && !offer_closed) ?
                                    <Button
                                      title="Reject"
                                      type="button"
                                      size="xl"
                                      theme="lightblue"
                                      mode="bordered"
                                      className="radius-8 fluid-x"
                                      loading={handleLoading(loading, "RemoveOfferInvestor")}
                                      onClick={this.deleteOffer}
                                      disabled={offerBidsCount}
                                    /> : null
                              }
                            </div>
                          </div>
                        </>
                      ) : null
                    }
                  </div>
                ) : (
                  <>
                    {!this.props.location.pathname.includes('offer') &&
                      <div className="radius-10 bg-white medium-flex-container align-middle align-justify project_direct_card">
                        <div className="flex-container align-middle project_direct_card__title">
                          <Icon type="video" size={32} style={{ marginRight: 14 }} className="color-grey" />
                          <div className="text text-18 font-heavy color-grey">
                            Video about opportunity
                          </div>
                        </div>
                        {this.printWatchVideoButton()}
                      </div>
                    }
                    <div className="bg-white radius-10 flex-container align-middle align-justify project_direct_card">
                      <div className="flex-container align-middle project_direct_card__title">
                        <Icon type="folders" size={32} style={{ marginRight: 16 }} className="color-grey" />
                        <div className="text text-18 font-heavy color-grey">
                          Data Room
                        </div>
                      </div>
                      {this.printDataRoomButton()}
                    </div>
                    {!this.props.location.pathname.includes('offer') &&
                      <div className="bg-white radius-10 flex-container align-middle align-justify project_direct_card">
                        <div className="flex-container align-middle project_direct_card__title">
                          <Icon type="portfolio" size={32} style={{ marginRight: 16 }} className="color-grey" />
                          <div className="text text-18 font-heavy color-grey">
                            Analytics
                          </div>
                        </div>
                        {this.printMyInvestmentsButton()}
                      </div>
                    }
                    {
                      !this.state.isAuction
                        ? (
                          <>
                            <InvestmentActions
                              isProgressStepActiveData={(index) => this.isProgressStepActiveData(index)}
                              isOpportunityExpired={this.isOpportunityExpired()}
                              renderOpportunityActionButtons={this.renderOpportunityActionButtons()}
                              interested={interested}
                              nda_signed={nda_signed}
                              ready_to_start={ready_to_start}
                              not_interested={not_interested}
                              allocation_amount={allocation_amount}
                              diligence_amount={diligence_amount}
                              internal_cost={internal_cost}
                              internal_sales={internal_sales}
                              internal_income={internal_income}
                              internal_prize={internal_prize}
                              external_cost={external_cost}
                              external_prize={external_prize}
                              external_sales={external_sales}
                              external_income={external_income}
                              initial_amount={initial_amount}
                              currency={currency}
                              real_diligence={real_diligence}
                              totalReceived={totalReceived}
                              totalSharesQuantity={totalSharesQuantity}
                              final_amount={final_amount}
                              involveRequest={involveRequest}
                              closed={closed}
                            />
                            {
                              this.isAdmin() && !this.state.isAuction
                                ? (
                                  null
                                ) : this.state.isAuction && (this.isAdmin() || (+xID === +offerUserID)) && (offer_closed !== undefined && !offer_closed) ?
                                  null
                                  : this.props.currentOpenedBusiness?.closed ? (<>
                                    {sixMonthDiff(this.props.currentOpenedBusiness?.closed) ? <ToolTip
                                      placement="leftTop"
                                      type="info-circle"
                                      className="tooltip"
                                      title='Shares can be sold after 6-month hold period'
                                    /> : null}
                                    <Button
                                      title="Sell my shares"
                                      type="button"
                                      size="md"
                                      theme="lightblue"
                                      mode="bordered"
                                      className="radius-8 fluid-x"
                                      style={{ marginTop: 32 }}
                                      onClick={() => history.push(`/add-offer/${businessID}`)}
                                      disabled={sixMonthDiff(this.props.currentOpenedBusiness?.closed)}
                                    /> </>
                                  ) : null
                            }
                          </>
                        ) : offerUserID && (+xID !== +offerUserID) ?
                          <AuctionActions
                            offerCalls={this.offerCalls}
                            internal={internal}
                            external={external}
                            lastBid={lastBid}
                            usersLastBid={lastBidInfo}
                            offer={match.params.id}
                            offerLeftTime={offerLeftTime}
                            bidsCount={this.props.offerBidsCount}
                            isFailed={winners === 0}
                            loading={loading}
                            sharesQty={shares_qty}
                            sharesPrice={shares_price}
                            currency={offer_currency}
                            success_qty={success_qty}
                            isShareholder={!!currentBusiness?.closed}
                            step={offer_step}
                            scope={scope}
                            userId={xID}
                            getWonBid={this.getWonBid}
                            ndaSigned={nda_signed}
                            offerBidRequest={offerBidRequest}
                            showNdaPopup={() => {
                              this.toggleIsInterested(true)
                            }}
                            showBidPopup={(bidAmount) => {
                              this.setState({
                                bidPopupVisible: true,
                                bidAmount,
                              })
                            }}
                            hideBidPopup={() => {
                              this.setState({
                                bidPopupVisible: false,
                              })
                            }}
                            offer_closed={offer_closed}
                          /> : (!compareTimes(start) && !compareTimes(end)) && //is not live 
                              ((+xID === +offerUserID) && (offer_closed || (internal.end && external.end))) ?
                              winners ?
                              <div className='bg-white radius-10 flex-container align-middle align-justify project_direct_card'>
                                <div className='project_direct_card__title'>
                                  <div className="text text-18 font-heavy color-grey">
                                    {`${external.stats.participants ? formatNumber(shares_qty) : formatNumber(returnSoldSharesAmount())} shares were sold, thank you.`}
                                  </div>
                                </div>
                              </div>
                              : <div className='bg-white radius-10 flex-container align-middle align-justify project_direct_card'>
                                <div className='project_direct_card__title'>
                                  <div className="text text-18 font-heavy color-grey">
                                    {`This deal was failed as it didn’t reached the minimum offer amount.`}
                                  </div>
                                </div>
                              </div>
                          : (!compareTimes(start) && !compareTimes(end)) && //is not live
                            ((+xID === +offerUserID) && (internal.approved || external.approved) &&
                            ((internal.end && !external.end) || (!internal.end && !external.end && !offer_closed))) ?
                            <div className='bg-white radius-10 flex-container align-middle align-justify project_direct_card'>
                              <div className='project_direct_card__title'>
                                {
                                  shares_qty - returnSoldSharesAmount() ?
                                  <>
                                    <div className="text text-18 font-heavy color-grey">
                                      {`The listing for Co-Shareholders is closed. ${formatNumber(returnSoldSharesAmount())} shares were sold.`}
                                    </div>
                                    <br />
                                    <div className="text text-18 font-heavy color-grey">
                                      {`The remaining ${formatNumber(shares_qty - returnSoldSharesAmount())} shares will be listed for All investors after the admin review.`}
                                    </div>
                                  </> : 
                                  <div className="text text-18 font-heavy color-grey">
                                    {`${formatNumber(returnSoldSharesAmount())} shares were sold, thank you.`}
                                  </div>
                                }
                              </div>
                            </div>
                          : <></>
                    }
                  </>
                )
            }
          </div>
          <div className="column small-12 large-7">
            <div className="medium-flex-container">
              {
                this.isAdmin() && !this.props.location.pathname.includes('offer')
                  ? (
                    <div className="medium-order-2 icon_btn project_edit_actions">
                      <div>
                        <Button
                          title={<Icon type="pen" style={{ fontSize: "17px" }} />}
                          size="lg"
                          theme="blue"
                          className="radius-10"
                          linkTo={`/admin-project/${id}`}
                        />
                      </div>
                    </div>
                  ) : null
              }
              <div className="fluid-x project_info">
                <div className="custom-headline text text-22 font-bold color-grey project_info__title">
                  <h2 className="flex-child-shrink">{description}</h2>
                  {
                    this.isAdmin() && !this.state.isAuction
                      ? (
                        <Button
                          title={enabled ? "Disable" : "Enable"}
                          size="md"
                          theme="lightblue"
                          mode="bordered"
                          className="radius-8 fluid-x"
                          type="button"
                          onClick={this.toggleBusinessVisibility}
                          loading={handleLoading(loading, 'DisableBusiness') || handleLoading(loading, 'EnableBusiness')}
                        />
                      )
                      // : this.state.isAuction && (this.isAdmin() || (+xID === +offerUserID)) && (offer_closed !== undefined && !offer_closed) ?
                      //   <div className="btn_group">
                      //     {!this.isAdmin() && <Button
                      //       title="Publish for All investors"
                      //       type="button"
                      //       size="md"
                      //       theme="lightblue"
                      //       mode="bordered"
                      //       className="radius-8 fluid-x"
                      //       disabled={(!offer_end_date || compareTimes(offer_end_date)) || external?.requested}
                      //       onClick={() => this.changePopUpView("requestExternalModalVisible")}
                      //     />}
                      //   </div>
                        : null
                  }
                </div>
                {!this.state.isAuction ? <InvestmentData {...rightSideDataProps()} /> : <AuctionData {...rightSideDataProps()} />}
                {
                  writeMessages && !this.props.location.pathname.includes('offer')
                    ? (
                      <div className="contact_direct">
                        <div className="text font-medium color-grey">
                          {!this.isAdmin() ? 'Any questions' : ''}
                        </div>
                        <Button
                          title={!this.isAdmin() ? 'Contact Us' : 'Contact Center'}
                          prefix={<Icon type="chat" style={{ marginRight: "14px" }} />}
                          size="lg"
                          theme="lightblue"
                          mode="bordered"
                          className="radius-10"
                          linkTo={`/contact-center/${id}/${name}/business`}
                        />
                      </div>
                    ) : null
                }
              </div>
            </div>
          </div>
        </div> : null}
        <div className="screen_wrapper" />

        <PopUp
          className="relative_parent"
          visible={popupNdaVisible}
          innerClassNames="shadow-layout bg-white radius-10 relative lg base"
        >
          <div
            onClick={this.hideNdaPopUp}
            className="popup_close color-grey-700"
          >
            <Icon type="cancel" />
          </div>
          <div className="popup_icon">
            <img src={require("../../assets/img/edit.svg")} alt="" />
          </div>
          <div className="text-center">
            <div className="text text-28 font-heavy font-spacing-02 color-grey font-uppercase mb_25">
              PLEASE, SIGN THE NDA BEFORE PROCEEDING
            </div>
            <div className="popup_icon_file">
              <Button
                title="Non disclosure agreement"
                prefix={<Icon type="file" style={{ marginRight: 10 }} />}
                size="lg"
                theme="white"
                className="btn-link"
                onClick={this.getNDAOnClick}
                style={{ fontSize: 18 }}
              />
            </div>

            <Form.Item className="mb_20">
              {getFieldDecorator("signature", {
                rules: [{ required: true, message: "Please fill out this field" }],
              })(
                <Input placeholder='Name Surname' className="fluid-x" />
              )}
            </Form.Item>

            <div className="flex-container align-center form-item-lg nda_checkbox">
              <Checkbox
                checked={isNdaCheckboxChecked}
                className="flex-container align-middle"
                onChange={this.handleNdaCheckboxOnChange}
              >
                <span className="text text-18 font-semibold color-grey">
                  I have read, understood and accepted the NDA
                </span>
              </Checkbox>
            </div>

            <Button
              type="submit"
              disabled={!isNdaCheckboxChecked}
              title="Sign NDA"
              size="xl"
              theme="blue"
              className="radius-8"
              style={{ fontSize: 18 }}
              onClick={this.signNdaOnClick}
              loading={handleLoading(businessLoading, "SignNDA")}
            />
          </div>
        </PopUp>

        <PopUp
          className="relative_parent"
          visible={bidPopupVisible}
          innerClassNames="shadow-layout bg-white radius-10 relative lg base"
        >
          <div
            onClick={this.hideBidPopUp}
            className="popup_close color-grey-700"
          >
            <Icon type="cancel" />
          </div>
          <div className="popup_icon">
            <img src={require("../../assets/img/edit.svg")} alt="" />
          </div>
          <div className="text-center">
            <div className="text text-28 font-heavy font-spacing-02 color-grey font-uppercase mb_25">
              SUBMIT BID
            </div>

            <Form.Item className="mb_20">
              {getFieldDecorator("bid_signature", {
                rules: [{ required: true, message: "Please fill out this field" }],
              })(
                <Input placeholder='Name Surname' className="fluid-x" />
              )}
            </Form.Item>

            <div className="mb_15">
              <span className="text text-18 font-semibold color-grey">
                {
                  scope === "EXTERNAL" ? 
                  `Please confirm your bid of ${formatNumber(shares_qty - internal.stats.amount)} shares for a total value of ${formatNumber(bidAmount)} ${currency}. By clicking "Confirm Bid",
                  you understand that you will not be able to change or retract your bid, and that you agree to buy the
                  shares you bid on if you are the winning bidder.` :
                  `Please confirm your bid of ${formatNumber(bidAmount)} shares for a total value of ${formatNumber(bidAmount * shares_price)} ${currency}. You may change your bid
                  prior to the deadline. "By clicking “Confirm Bid", you agree to purchase up to the total number of shares you have bid on.`
                }
              </span>
            </div>

            <Button
              type="submit"
              title="Confirm Bid"
              size="xl"
              theme="blue"
              className="radius-8"
              style={{ fontSize: 18 }}
              onClick={this.confirmBidOnClick}
              loading={handleLoading(loading, "AddBid")}
            />
          </div>
        </PopUp>

        <PopUp
          className="relative_parent"
          visible={popupVisible}
          innerClassNames="shadow-layout bg-white radius-10 relative sm base"
        >
          <div className="popup_icon">
            <img src={require("../../assets/img/completed.svg")} alt="" />
          </div>
          <div className="text-center">
            <div className="text text-28 font-heavy font-spacing-02 color-grey font-uppercase mb_25">
              Thank you!
            </div>
            <div className="flex-container align-center">
              <div className="text text-20 text-height-30 font-medium color-grey mb_45 large-10">
                PE Gate will contact you regarding due diligence funding
              </div>
            </div>

            <Button
              title="OK"
              size="xl"
              theme="blue"
              className="radius-8"
              style={{ fontSize: 18 }}
              onClick={() => this.toggleModal(false)}
            />
          </div>
        </PopUp>

        <PopUp
          className="relative_parent"
          visible={popupSuccessVisible}
          innerClassNames="shadow-layout bg-white radius-10 relative sm base"
        >
          <div className="popup_icon">
            <img src={require("../../assets/img/completed.svg")} alt="" />
          </div>
          <div className="text-center">
            <div className="text text-28 font-heavy font-spacing-02 color-grey font-uppercase mb_25">
              Success!
            </div>
            <div className="flex-container flex-wrap align-center">
              <div className="text text-20 text-height-30 font-medium color-grey mb_30 large-11 relative separated">
                Dear {firstName}, thank you for your interest in this investment opportunity
              </div>

              <div className="text text-20 text-height-30 font-bold color-grey mb_45 large-11">
                We are reviewing your request and will be in touch with you shortly
              </div>
            </div>

            <Button
              title="OK"
              size="xl"
              theme="blue"
              className="radius-8"
              style={{ fontSize: 18 }}
              onClick={() => this.toggleModalSuccess(false)}
            />
          </div>
        </PopUp>
        
        <PopUp
          className="relative_parent"
          visible={submissionDetailsVisible}
          innerClassNames="shadow-layout bg-white radius-10 relative sm base"
        >
           <div className="popup_close color-grey-700" onClick={() => this.setState({ submissionDetailsVisible: false })}>
              <Icon type="cancel" />
            </div>
          <div className="text-center">
            <div className="text text-28 font-heavy font-spacing-02 color-grey font-uppercase mb_25">
              Submission Details
            </div>
          </div>
          <div className="medium-flex-container align-justify project_data_row">
            <div className="text text-14 font-medium color-grey project_data_col"> % of HoldCo. Owned</div>
            <div className="text font-bold color-grey project_data_col">{calcFieldValues?.SPV}</div>
          </div>
          <div className="medium-flex-container align-justify project_data_row">
            <div className="text text-14 font-medium color-grey project_data_col"> % of OpCo. Owned</div>
            <div className="text font-bold color-grey project_data_col">{calcFieldValues?.operatingCompanyOwned}</div>
          </div>
          <div className="medium-flex-container align-justify project_data_row">
            <div className="text text-14 font-medium color-grey project_data_col">Number Of Shares Owned</div>
            <div className="text font-bold color-grey project_data_col">{formatNumber(calcFieldValues?.finalAmount)}</div>
          </div>
          <div className="medium-flex-container align-justify project_data_row">
            <div className="text text-14 font-medium color-grey project_data_col">Average Share Price</div>
            <div className="text font-bold color-grey project_data_col">{calcFieldValues?.originalSharePrice?.toFixed(2)} {currency}</div>
          </div>
          <div className="medium-flex-container align-justify project_data_row">
            <div className="text text-14 font-medium color-grey project_data_col">Investment</div>
            <div className="text font-bold color-grey project_data_col">{formatNumber(calcFieldValues?.finalAmount)} {currency}</div>
          </div>
          <div className="medium-flex-container align-justify project_data_row">
            <div className="text text-14 font-medium color-grey project_data_col">Total value of distributions received, net of fees</div>
            <div className="text font-bold color-grey project_data_col">{formatNumber(calcFieldValues?.distributions)} {currency}</div>
          </div>
          <div className="medium-flex-container align-justify project_data_row">
            <div className="text text-14 font-medium color-grey project_data_col">% ROC</div>
            <div className="text font-bold color-grey project_data_col">{calcFieldValues?.ROI}</div>
          </div>
          <div className="medium-flex-container align-justify project_data_row">
            <div className="text text-14 font-medium color-grey project_data_col"> % Annualized ROC</div>
            <div className="text font-bold color-grey project_data_col">{calcFieldValues?.ROIAnnualized}</div>
          </div>
          <div className="medium-flex-container align-justify project_data_row">
            <div className="text text-14 font-medium color-grey project_data_col">Number of Shares to sell</div>
            <div className="text font-bold color-grey project_data_col">{formatNumber(shares_qty)}</div>
          </div>
          <div className="medium-flex-container align-justify project_data_row">
            <div className="text text-14 font-medium color-grey project_data_col">Price per share</div>
            <div className="text font-bold color-grey project_data_col">{formatNumber(shares_price)} {currency}</div>
          </div>
          <div className="medium-flex-container align-justify project_data_row">
            <div className="text text-14 font-medium color-grey project_data_col">Shares percent</div>
            <div className="text font-bold color-grey project_data_col">{shares_percent} %</div>
          </div>
          <div className="medium-flex-container align-justify project_data_row">
            <div className="text text-14 font-medium color-grey project_data_col">Position for a value of</div>
            <div className="text font-bold color-grey project_data_col">{formatNumber(shares_qty * shares_price)} {currency}</div>
          </div>
          <div className="medium-flex-container align-justify project_data_row">
            <div className="text text-14 font-medium color-grey project_data_col">Minimum to close</div>
            <div className="text font-bold color-grey project_data_col">{formatNumber(success_qty)}</div>
          </div>
          <div className="medium-flex-container align-justify project_data_row">
            <div className="text text-14 font-medium color-grey project_data_col">Minimum transaction size</div>
            <div className="text font-bold color-grey project_data_col">{formatNumber(success_qty * shares_price)} {currency}</div>
          </div>
          <div className="medium-flex-container align-justify project_data_row">
            <div className="text text-14 font-medium color-grey project_data_col">% Projected ROC After Sale</div>
            <div className="text font-bold color-grey project_data_col">{calcFieldValues?.RDE}</div>
          </div>
          <div className="medium-flex-container align-justify project_data_row">
            <div className="text text-14 font-medium color-grey project_data_col">% Projected Annualized ROC After Sale</div>
            <div className="text font-bold color-grey project_data_col">{calcFieldValues?.RDEAnnualized}</div>
          </div>
        </PopUp>

        <PopUp
          className="relative_parent"
          visible={amountPopupVisible}
          innerClassNames="shadow-layout bg-white radius-10 relative sm base"
        >
          <div
            onClick={() => this.toggleAmountPopup(false)}
            className="popup_close color-grey-700"
          >
            <Icon type="cancel" />
          </div>
          <div className="popup_icon">
            <img src={require("../../assets/img/money.svg")} alt="" />
          </div>
          <div className="text-center">
            <div className="text text-28 font-heavy font-spacing-02 color-grey font-uppercase mb_25">
              Please enter the amount you want to invest
            </div>
            <Form name="invest-amount" onSubmit={this.onSubmit} >
              <div className="form-item text-left mb_40">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  INVESTMENT AMOUNT
                </div>
                <Form.Item className="mb_20">
                  {getFieldDecorator("amount", {
                    rules: [{ required: true, message: "Please fill out this field" }],
                  })(
                    <NumericInput className="fluid-x" />
                  )}
                </Form.Item>
                <div className="form-item-hint text text-12 font-medium font-spacing-02 color-grey-800 mt_0">
                  Minimum Investment: {formatNumber(minimum_investment)} {currency}
                </div>
              </div>
              <Form.Item className="mb_0 text-right">
                <Button
                  title="Cancel"
                  size="xl"
                  theme="default"
                  className="font-uppercase text"
                  style={{ fontSize: 15 }}
                  onClick={() => this.toggleAmountPopup(false)}
                />
                <Button
                  title="Confirm"
                  size="xl"
                  theme="blue"
                  type="submit"
                  className="radius-8"
                  style={{ fontSize: 18 }}
                  loading={handleLoading(loading, "ReadyToStart")}
                />
              </Form.Item>
            </Form>
          </div>
        </PopUp>

        <PopUp
          className="relative_parent"
          visible={nonOnboardedMessagePopupVisible}
          innerClassNames="shadow-layout bg-white radius-10 relative sm base"
        >
          <div
            onClick={() => this.toggleOnboardedMessageModal(false)}
            className="popup_close color-grey-700"
          >
            <Icon type="cancel" />
          </div>

          <div className="text text-20 text-height-30 font-medium color-grey mb_30 large-11 relative separated">
            <Icon
              type="info-circle"
              className="color-yellow"
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 16,
              }}
              size={24}
            />
            <p className="text-center">
              {
                !this.isOnboardingFormFilled() ?
                  <>
                    To access Investment Opportunities, you must become an Onboarded Investor. Please click
                    <Link to='/onboarding1' className="font-medium color-yellow"> here </Link>
                    to register as an Onboarded Investor
                  </>
                  :
                  <>Your Onboarding application was successfully submitted.  We will be in touch with you shortly.</>
              }
            </p>
          </div>
        </PopUp>

        <PopUp
          className="relative_parent"
          visible={investmentActionMessagePopupInfo.visible}
          innerClassNames="shadow-layout bg-white radius-10 relative sm base"
        >
          <div
            onClick={() => this.toggleInvestmentActionMessageModal('', false)}
            className="popup_close color-grey-700"
          >
            <Icon type="cancel" />
          </div>

          <div className="text text-20 text-height-30 font-medium color-grey mb_30 large-11 relative separated">
            <Icon
              type="info-circle"
              className="color-yellow"
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 16,
              }}
              size={24}
            />
            <p className="text-center">
              You will be able to {investmentActionMessagePopupInfo.type} to this investment once we approve your application
            </p>
          </div>
        </PopUp>

        {
          popupVideoVisible && videoStreamURL ?
            <PopUp
              className="relative_parent"
              visible
              innerClassNames="shadow-layout bg-white radius-10 relative xl pined-top has-video"
            >
              <div
                onClick={() => this.toggleVideoModal(false)}
                className="popup_close color-grey-700"
              >
                <Icon type="cancel" />
              </div>
              <video
                autoPlay
                controls
                loop
                style={{ width: "100%" }}
              >
                <source src={videoStreamURL} type="video/mp4" />
              </video>
            </PopUp>
            : null
        }

        <PopUp
          className="relative_parent"
          visible={internalModalVisible}
          innerClassNames="shadow-layout bg-white radius-10 relative lg base"
        >
          <InternalPublishForm
            modalVisible={internalModalVisible}
            changePopUpView={this.changePopUpView}
            offeredSuccessQty={success_qty ? success_qty * 0.2 : null}
            sharesQty={shares_qty}
            submitInternalPublish={this.submitInternalPublish}
          />
        </PopUp>

        <PopUp
          className="relative_parent"
          visible={externalModalVisible}
          innerClassNames="shadow-layout bg-white radius-10 relative lg base"
        >
          <ExternalPublishForm
            modalVisible={externalModalVisible}
            changePopUpView={this.changePopUpView}
            submitExternalPublish={this.submitExternalPublish}
          />
        </PopUp>

        <PopUp
          className="relative_parent"
          visible={removeOfferModalVisible}
          innerClassNames="shadow-layout bg-white radius-10 relative lg base"
        >
          <DeleteOfferForm
            changePopUpView={this.changePopUpView}
            offerID={match.params.id}
            removeOfferAdmin={removeOfferAdmin}
          />
        </PopUp>

        <PopUp
          className="relative_parent"
          visible={requestExternalModalVisible}
          innerClassNames="shadow-layout bg-white radius-10 relative lg base"
        >
          <RequestExternalPublishForm
            changePopUpView={this.changePopUpView}
            offerID={match.params.id}
            requestExternalPublish={this.requestExternalPublish}
          />
        </PopUp>

        <PopUp
          className="relative_parent"
          visible={this.state.submitModalVisible}
          innerClassNames="shadow-layout bg-white radius-10 relative sm base"
        >
          <>
            <div className="popup_icon">
              <img src={require("../../assets/img/completed.svg")} alt="" />
            </div>
            <div className="text-center">
              <div className="flex-container align-center">
                <div className="text text-20 text-height-30 font-medium color-grey mb_45 large-10">
                  Thank you for your submission. A PE Gate admin will review your application and reach out within the next 48 hours.
                </div>
              </div>

              <Button
                title="OK"
                size="xl"
                theme="blue"
                className="radius-8"
                style={{ fontSize: 18 }}
                onClick={() => this.changePopUpView('submitModalVisible')}
              />
            </div>
          </>
        </PopUp>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  xID: state.user.xID,
  xTOKEN: state.user.xTOKEN,
  loading: window.location.pathname.includes('offer') ? state.offers.loading : state.business.loading,
  businessLoading: state.business.loading,
  errorMessage: window.location.pathname.includes('offer') ? state.offers.errorMessage : state.business.errorMessage,
  businessErrorMessage: state.business.errorMessage,
  opportunityGroups: state.dictionaries.opportunityGroups,
  countries: state.dictionaries.countries,
  states: state.dictionaries.states,
  businesses: state.business.businesses,
  businessSummary: state.business.businessSummary,
  userInfo: state.user.userInfo,
  role: state.user.role,
  currentOpenedBusiness: state.business.currentOpenedBusiness,
  onboardingData: state.registration.onboardingData,
  currentOffer: state.offers.selectedOffer,
  offerBidsCount: state.offers.offerBidsCount,
  offerBidRequest: state.offers.offerBidRequest,
  usersLastBid: state.offers.usersLastBid,
  businessReports: state.reports.businessReports,
  involveRequest: state.business.involveRequest,
});

const mapDispatchToProps = dispatch => ({
  setInterested: (businessID, isInterested) => dispatch(setInterested(businessID, isInterested)),
  getStates: countryID => dispatch(getStates(countryID)),
  removeStates: () => dispatch(removeStates()),
  signNDA: (business, signature) => dispatch(signNDA(business, signature)),
  getNDA: () => dispatch(getNDA()),
  readyToStart: (business, amount) => dispatch(readyToStart(business, amount)),
  readyToCommit: business => dispatch(readyToCommit(business)),
  readyToClose: business => dispatch(readyToClose(business)),
  removeCurrentBusiness: () => dispatch(removeCurrentBusiness()),
  getBusiness: (businessID) => dispatch(getBusiness(businessID)),
  getBusinessInvolveRequest: (businessID) => dispatch(getBusinessInvolveRequest(businessID)),
  setActivePortfolioTabKey: (tabKey) => dispatch(setActivePortfolioTabKey(tabKey)),
  setActiveInvestmentId: (activeInvestmentId) => dispatch(setActiveInvestmentId(activeInvestmentId)),
  enableBusiness: (businessID) => dispatch(enableBusiness(businessID)),
  disableBusiness: (businessID) => dispatch(disableBusiness(businessID)),
  getBusinessSummary: (businessID) => dispatch(businessSummary(businessID)),
  getOffer: (params) => dispatch(getOffer(params)),
  internalPublish: params => dispatch(internalPublish(params)),
  externalPublish: params => dispatch(externalPublish(params)),
  closeOffer: params => dispatch(closeOffer(params)),
  removeOfferAdmin: (params) => dispatch(removeOfferAdmin(params)),
  removeOfferInvestor: (params) => dispatch(removeOfferInvestor(params)),
  searchOfferBidsCount: (params) => dispatch(searchOfferBidsCount(params)),
  searchBids: (params) => dispatch(searchBids(params)),
  requestExternalSales: (params) => dispatch(requestExternalSales(params)),
  clearCurrentOffer: (params) => dispatch(clearOffer()),
  getReport: (userID, currency, businessID) => dispatch(getBusinessReport(userID, currency, businessID)),
  addExternalBid: (offer, amount) => dispatch(addExternalBid(offer, amount)),
  addInternalBid: (offer, amount) => dispatch(addInternalBid(offer, amount)),
  acceptInvolveRequest: params => dispatch(acceptInvolveRequest(params)),
  rejectInvolveRequest: params => dispatch(rejectInvolveRequest(params)),
  resetInvolveRequestData: () => dispatch(resetInvolveRequestData()),
  resetOfferBidRequest: () => dispatch(resetOfferBidRequest()),
  getOfferBidRequest: params => dispatch(getOfferBidRequest(params)),
});

const ProjectScreen = Form.create({ name: 'investAmount' })(Project);
export default connect(mapStateToProps, mapDispatchToProps)(ProjectScreen);
