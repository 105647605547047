import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Checkbox,
  Form,
  Input,
  message,
  // Select,
} from "antd";

import { goBack } from "../../utils/goBack";

import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import { handleLoading } from "../../utils/handleLoading";
import { createOffers } from "../../redux/business/thunks/offersThunk";
import "./style.scss";
import NumericInput from "../../components/NumericInput/NumericInput";
import PopUp from "../../components/PopUp/PopUp";
import { handleOperationSuccess } from "../../utils/handleOperationSuccess";
import { handleErrorMessage } from "../../utils/handleErrorMessage";
import { ToolTip } from "../../components/Tooltip";
import { businessSummary, getBusiness } from "../../redux/business/thunks/businessThunk";
import { calcFieldsValue } from "../../utils/calculateOfferFieldsValue";
import { getBusinessReport } from "../../redux/reports/thunks/reportsThunk";
import { numberFormat } from 'highcharts';
import { formatNumber } from '../../utils/formatNumber';

// const { Option } = Select;
let calcFieldValues = {
  SPV: 0,
  operatingCompanyOwned: 0,
  originalSharePrice: 1,
  finalAmount: 0,
  investment: 0,
  distributions: 0,
  InvestmentToBeSold: 0,
  ROI: 0,
  ROIAnnualized: 0,
  RDE: 0,
  RDEAnnualized: 0,
};

class Offer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initState: true,
      preformenceSelect: [
        { title: 'Management Performance', name: 'management_performance', tooltipTitle: "Rank the Company's Management", optionNameChange: true },
        { title: 'Financial Performance', name: 'financial_performance', tooltipTitle: "Rank the Company's financial performance", optionNameChange: true },
        { title: 'Sector Performance', name: 'sector_performance', tooltipTitle: "Rank the Company's sector performance", optionNameChange: true },
        { title: 'Competition', name: 'competition', tooltipTitle: "Rank the Company's competition" },
        { title: 'Barriers To Entry', name: 'barriers_to_entry', tooltipTitle: "Rank level of barriers to entry" },
        { title: 'Growth Potential', name: 'growth_potential', tooltipTitle: "Rank the Company's growth potential" },
      ],
      submitModalVisible: false,
      popupTermsVisible: false,
      selectedReasonValue: false,
      isTermsAgreed: false,
      pricePerShareValue: '',
    }
  }

  onSubmit = event => {
    event.preventDefault();
    const businessID = this.props.match.params.id;
    const shares_percent = parseFloat(calcFieldValues.InvestmentToBeSold);

    this.props.form.validateFields((err, values) => {
      if (!err) {
        delete values.check;
        this.props.createOffer({ business: businessID, ...values, shares_percent });
      }
    });
  };

  closeSubmitModal = () => {
    this.setState({ submitModalVisible: false });
    this.props.history.push('/offers');
  };

  hideTermsPopup = () => {
    const { setFieldsValue, getFieldValue } = this.props.form;

    this.setState({ popupTermsVisible: false });
    setFieldsValue({ "check": !getFieldValue("check") });
  };

  showTermsPopup = () => {
    this.setState({ popupTermsVisible: true });
  };

  agreeTermsOk = () => {
    this.setState({ popupTermsVisible: false });
    this.props.form.setFieldsValue({ "check": true });
  };

  agreeTermsCancel = () => {
    this.setState({ popupTermsVisible: false });
    this.props.form.setFieldsValue({ "check": false });
    this.props.form.setFieldsValue({ "termsSignature": '' });
  };

  selectedReason = (value) => {
    if (!this.state.selectedReasonValue && value === 207) {
      this.setState({ selectedReasonValue: true })
    } else if (this.state.selectedReasonValue && value !== 207) {
      this.setState({ selectedReasonValue: false })
    }
  };

  getCalcFieldData = () => {
    const { business, businessSummary, businessReports } = this.props;

    if (business && businessSummary) {
      return ({
        totalReceived: businessSummary.final_amount,
        finalAmount: ((business.final_amount + business.external_prize + business.internal_prize) - business.internal_sales) - business.external_sales,
        investment: ((business.final_amount + business.external_cost + business.internal_cost) - business.internal_income) - business.external_income,
        shareholding: business.pegate_shareholding || 0,
        shareholdingMain: business.shareholding || 0,
        originalSharePrice: 1,
        businessReports: businessReports,
        businessClosedDate: business.closed,
        sharesQty: this.props.form.getFieldValue('shares_qty'),
        price: this.props.form.getFieldValue('shares_price')
      });
    }
    return false;
  };

  componentDidMount() {
    const { getBusinessSummary, getBusiness, match, userID, business, getReport } = this.props;
    const businessID = match.params.id
    getBusiness(businessID)
    getBusinessSummary(businessID)
    getReport(userID, business?.currency, businessID)
  }

  componentDidUpdate(prevProps) {
    const { errorMessage, loading } = this.props;
    // const businessID = match.params.id

    if (handleErrorMessage(errorMessage, prevProps.errorMessage, "CreateOffers")) {
      message.error(this.props.errorMessage.message);
    }
    if (handleOperationSuccess(errorMessage, loading, prevProps.loading.state, "CreateOffers")) {
      this.setState({ submitModalVisible: true })
    }
    // if (handleOperationSuccess(businessErrorMessage, businessLoading, prevProps.businessLoading.state, "GetBusiness")) {
    //   getReport(userID, business?.currency, businessID)
    // }
    // if (reportsLoading.state === false && prevProps.reportsLoading.state === "GetBusinessReport") {
    //   this.setState({ initState: true })
    // }
  }

  render() {
    const { getFieldDecorator, setFieldsValue, getFieldValue } = this.props.form;
    const { loading, business } = this.props;
    const { popupTermsVisible } = this.state
    const isTermsAndAgreeChecked = getFieldValue('check') && getFieldValue('agree');
    const price = getFieldValue('shares_price');
    const sharesQty = getFieldValue('shares_qty');
    const successQty = getFieldValue('success_qty');
    // console.log(calcFieldValues);
    // if (businessReports && this.state.initState) {
    //   this.setState({ initState: false })
    const fieldsData = this.getCalcFieldData();
    const isTermsInputFilled = !!getFieldValue('termsSignature')?.trim();
    calcFieldValues = fieldsData ? calcFieldsValue(fieldsData) : "";
    // }

    return (
      <div className="relative fluid-y screen_wrapper">
        <div className="row">
          <div className="column small-12">
            <div className="page_top page_top-tall">
              <div className="flex-container align-middle">
                <Button
                  title={<Icon type="key-left" size={16} />}
                  size="md"
                  onClick={goBack}
                  theme="lightblue"
                  className="radius-10 back_btn"
                />
                <div className="page_title visible-first custom-headline text text-28 text-height-48 font-heavy font-uppercase color-grey">
                  <h1>Sell My Shares Form</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          !this.state.submitModalVisible
            ? (
              <Form onSubmit={this.onSubmit}>
                <div className="row align-center mb_35 add_offer_form">
                  <div className="column small-12 medium-11">
                    <div className="sign_out_form_card add_form bg-white radius-10 shadow-layout">
                      <div className="add_offer row">
                        <div className="custom-headline text text-22 font-bold color-grey">
                          <h2 className="flex-child-shrink">{this.props?.business?.name}</h2>
                        </div>
                        <div className="row medium-12 small-12 block-group radius-8">
                          <span className="block-title text text-height-20 font-semibold color-grey-800"> Ownership </span>
                          <div className="column small-12 medium-block">
                            <div className=" row form-item mb_40">
                              <div className="medium-6 small-12 form-item-label text text-height-20 font-semibold color-grey align-self-middle">
                                % of HoldCo. Owned
                              </div>
                              <Form.Item className="mb_0 small-12 medium-6">
                                <Input value={calcFieldValues?.SPV} disabled className="fluid-x ant-input-number" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="column small-12 medium-block">
                            <div className="row form-item mb_40">
                              <div className="medium-6 small-12 form-item-label text text-height-20 font-semibold color-grey align-self-middle">
                                % of OpCo. Owned
                              </div>
                              <Form.Item className="mb_0 small-12 medium-6">
                                <Input value={calcFieldValues?.operatingCompanyOwned} disabled className="fluid-x ant-input-number" />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className="row medium-12 small-12 block-group radius-8">
                          <span className="block-title text text-height-20 font-semibold color-grey-800">Investment And Distributions</span>

                          <div className="column small-12 medium-block">
                            <div className="row form-item mb_40">
                              <div className="medium-6 small-12 form-item-label text text-height-20 font-semibold color-grey align-self-middle">
                                Number Of Shares Owned
                              </div>
                              <Form.Item className="mb_0 small-12 medium-6 form-item lg">
                                <NumericInput
                                  value={(calcFieldValues?.finalAmount) / calcFieldValues?.originalSharePrice}
                                  disabled className="fluid-x"
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="column small-12 medium-block">
                            <div className="row form-item mb_40">
                              <div className="medium-6 small-12 form-item-label text text-height-20 font-semibold color-grey align-self-middle">
                                Average Share Price
                              </div>
                              <Form.Item className="mb_0 small-12 medium-6 form-item lg">
                                <Input
                                  value={calcFieldValues?.originalSharePrice?.toFixed(2)}
                                  suffix={<div className='text text-20 font-semibold color-blue-2'>{this.props?.business?.currency}</div>}
                                  disabled className="fluid-x"
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="column small-12 medium-block">
                            <div className="row form-item mb_40">
                              <div className="medium-6 small-12 form-item-label text text-height-20 font-semibold color-grey align-self-middle">
                                Your Investment
                              </div>
                              <Form.Item className="mb_0 small-12 medium-6 form-item lg">
                                <NumericInput
                                  value={calcFieldValues?.investment}
                                  suffix={<div className='text text-20 font-semibold color-blue-2'>{this.props?.business?.currency}</div>}
                                  disabled className="fluid-x"
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="column small-12 medium-block">
                            <div className="row form-item mb_40">
                              <div className="medium-6 small-12 form-item-label text text-height-20 font-semibold color-grey align-self-middle">
                                Total value of distributions received, net of fees
                                <ToolTip placement="rightTop" title={`Purchased at 1 ${this.props?.business?.currency} per share`} />
                              </div>
                              <Form.Item className="mb_0 small-12 medium-6 form-item lg">
                                <NumericInput
                                  value={calcFieldValues?.distributions}
                                  suffix={<div className='text text-20 font-semibold color-blue-2'>{this.props?.business?.currency}</div>}
                                  disabled
                                  className="fluid-x"
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="column small-12 medium-block">
                              <div className="row form-item mb_40">
                                <div className="medium-6 small-12 form-item-label text text-height-20 font-semibold color-grey align-self-middle">
                                  % ROC
                                  <ToolTip placement="rightTop" title='Percent Return on Capital' />
                                </div>
                                <Form.Item className="mb_0 small-12 medium-6">
                                  <Input placeholder="Enter Sale price to calculate" value={calcFieldValues?.ROI} disabled className="fluid-x auto_populated" />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="column small-12 medium-block">
                              <div className="row form-item mb_40">
                                <div className="medium-6 small-12 form-item-label text text-height-20 font-semibold color-grey align-self-middle">
                                  % Annualized ROC
                                  <ToolTip placement="rightTop" title='Annualized Percent Return on Capital' />
                                </div>
                                <Form.Item className="mb_0 small-12 medium-6">
                                  <Input placeholder="Enter Sale price to calculate" value={calcFieldValues?.ROIAnnualized} disabled className="fluid-x auto_populated" />
                                </Form.Item>
                              </div>
                            </div>
                        </div>
                        <div className="row medium-12 small-12 block-group radius-8">
                          <span className="block-title text text-height-20 font-semibold color-grey-800">Sale</span>

                          <div className="row medium-12 small-12 return-block">
                            <div className="column small-12 medium-block">
                              <div className="row form-item mb_40">
                                <div className="medium-6 small-12 form-item-label text text-height-20 font-semibold color-grey align-self-middle">
                                  Number of Shares to sell
                                  <ToolTip placement="rightTop" title='Number of shares to sell' />
                                </div>
                                <Form.Item className="mb_0 small-12 medium-6">
                                  {getFieldDecorator("shares_qty", {
                                    rules: [
                                      { required: true, message: "Please fill out this field" },
                                      {
                                        validator: async (rule, value) => {
                                          if (parseInt(value) > fieldsData.finalAmount) {
                                            return Promise.reject(`Shares Quantity cant be more than ${formatNumber(fieldsData.finalAmount)}`);
                                          } else if(value < 1) {
                                            return Promise.reject("Shares Quantity cant be less than 1");
                                          }
                                        },
                                      },
                                    ]
                                  })(
                                    <NumericInput
                                      className="fluid-x"
                                    />
                                  )}
                                </Form.Item>
                              </div>
                            </div>
                            <div className="column small-12 medium-block">
                              <div className="row form-item mb_40">
                                <div className="medium-6 small-12 form-item-label text text-height-20 font-semibold color-grey align-self-middle">
                                  Price per share you wish to sell
                                  <ToolTip placement="rightTop" title={`Total ${this.props?.business?.currency} value of shares you wish to start the auction at.`} />
                                </div>
                                <Form.Item className="mb_0 small-12 medium-6 form-item lg">
                                  {getFieldDecorator("shares_price", {
                                    rules: [
                                      { required: true, message: "Please fill out this field" },
                                      {
                                        validator: async (rule, value) => {
                                          if (parseFloat(value) <= 0) {
                                            return Promise.reject('Shares Price need to be greater than 0');
                                          }
                                        },
                                      },
                                    ],
                                  })(
                                    <NumericInput
                                      onChange={() => this.setState({ initState: true })}
                                      onBlur={() => {
                                        // setFieldsValue({ "shares_price": getFieldValue("shares_price").toFixed(2) })
                                      }}
                                      step={0.25}
                                      float
                                      suffix={<div className='text text-20 font-semibold color-blue-2'>{this.props?.business?.currency}</div>}
                                      className="fluid-x"
                                    />
                                  )}
                                </Form.Item>
                              </div>
                            </div>
                            <div className="column small-12 medium-block">
                              <div className="row form-item mb_40">
                                <div className="medium-6 small-12 form-item-label text text-height-20 font-semibold color-grey align-self-middle">
                                  You are Offering
                                </div>
                                <Form.Item className="mb_0 small-12 medium-6">
                                  <Input value={!isNaN(sharesQty) ? calcFieldValues?.InvestmentToBeSold : ''} disabled className="fluid-x auto_populated" />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="column small-12 medium-block">
                              <div className="row form-item mb_40">
                                <div className="medium-6 small-12 form-item-label text text-height-20 font-semibold color-grey align-self-middle">
                                  of your position for a value of:
                                </div>
                                <Form.Item className="mb_0 small-12 medium-6 form-item lg">
                                  <NumericInput
                                    value={!isNaN(sharesQty) && price ? sharesQty * price : ''}
                                    suffix={<div className='text text-20 font-semibold color-blue-2'>{this.props?.business?.currency}</div>}
                                    disabled
                                    className="fluid-x auto_populated"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="column small-12 medium-block">
                              <div className="row form-item mb_40">
                                <div className="medium-6 small-12 form-item-label text text-height-20 font-semibold color-grey align-self-middle">
                                  Minimum to close
                                  <ToolTip placement="rightTop" title='Enter the fewest number of shares/units you would sell at this price (note: this minimum is applicable to Internal Offerings only)' />
                                </div>
                                <Form.Item className="mb_0 small-12 medium-6">
                                  {getFieldDecorator("success_qty", {
                                    rules: [
                                      { required: true, message: "Please fill out this field" },
                                      {
                                        validator: async (rule, value) => {
                                          if (parseFloat(value) > sharesQty) {
                                            return Promise.reject('Minimum to close can\'t be greater than number of Shares to sell');
                                          }
                                          if (parseFloat(value) < 1) {
                                            return Promise.reject('Minimum to close can\'t be 0 or less');
                                          }
                                        },
                                      },
                                    ]
                                  })(
                                    <NumericInput
                                      onChange={() => this.setState({ initState: true })}
                                      className="fluid-x"
                                    />
                                  )}
                                </Form.Item>
                              </div>
                            </div>
                            <div className="column small-12 medium-block">
                              <div className="row form-item mb_40">
                                <div className="medium-6 small-12 form-item-label text text-height-20 font-semibold color-grey align-self-middle">
                                  Minimum transaction size
                                  <ToolTip placement="rightTop" title='Price per share x Minimum Offer' />
                                </div>
                                <Form.Item className="mb_0 small-12 medium-6 form-item lg">
                                  <NumericInput
                                    value={!isNaN(successQty) && price ? successQty * price : ''}
                                    suffix={<div className='text text-20 font-semibold color-blue-2'>{this.props?.business?.currency}</div>}
                                    disabled
                                    className="fluid-x auto_populated"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="block-group radius-8">
                          <span className="block-title text text-height-20 font-semibold color-grey-800">Implied Returns</span>

                          <div className="row medium-12 small-12 return-block">
                            <div className="column small-12 medium-block">
                              <div className="row form-item mb_40">
                                <div className="medium-6 small-12 form-item-label text text-height-20 font-semibold color-grey align-self-middle">
                                  % Projected ROC After Sale
                                  <ToolTip placement="rightTop" title='Percent Return on Capital including gain/loss from sale' />
                                </div>
                                <Form.Item className="mb_0 small-12 medium-6">
                                  <Input placeholder="Enter Sale price to calculate" value={price ? calcFieldValues?.RDE : ''} disabled className="fluid-x auto_populated" />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="column small-12 medium-block">
                              <div className="row form-item mb_40">
                                <div className="medium-6 small-12 form-item-label text text-height-20 font-semibold color-grey align-self-middle">
                                  % Projected Annualized ROC After Sale
                                  <ToolTip placement="rightTop" title='Annualized Percent Return on Capital including gain/loss from sale' />
                                </div>
                                <Form.Item className="mb_0 small-12 medium-6">
                                  <Input placeholder="Enter Sale price to calculate" value={price ? calcFieldValues?.RDEAnnualized : ''} disabled className="fluid-x auto_populated" />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="column small-12 medium-12">
                          <div className="row form-item mb_40">
                            <div className="medium-4 small-12 align-self-middle form-item-label text text-height-20 font-semibold color-grey">
                              Auction Style
                              <ToolTip placement="rightTop" title={<>
                                <b>Blind:</b> Interested buyers bid without seeing other buyers' bids. Highest bid at auction end wins. <br />
                                <b>Open: </b> Interested buyers bid with visibility of other buyers' bids. Highest bid at auction end wins.
                              </>} />
                            </div>
                            <Form.Item className="mb_0 small-12 medium-8">
                              {getFieldDecorator('mode', {
                                rules: [{ required: true, message: "Please fill out this field" }]
                              })(
                                <Select className="fluid-x" >
                                  {INVESTOR_MODE.map((i) => <Option key={i} value={i}>{i}</Option>)}
                                </Select>
                              )}
                            </Form.Item>
                          </div>
                        </div> */}
                        {/* <div className="column small-12 medium-12 p_0">
                          <div className="form-item mb_40">
                            <div className="form-item-label text text-height-20 font-semibold color-grey">
                              Company
                              <ToolTip placement="rightTop" title='Add any comment(s) about the company' />
                            </div>
                            <Form.Item className="mb_0">
                              {getFieldDecorator("company", {
                                rules: [{ required: true, message: "Please fill out this field" }]
                              })(
                                <TextArea />
                              )}
                            </Form.Item>
                          </div>
                        </div> */}
                        {/* <div className="column small-12 medium-12 p_0">
                          <div className="form-item mb_40">
                            <div className="form-item-label text text-height-20 font-semibold color-grey">
                              PE Gate Team
                              <ToolTip placement="rightTop" title="Add any comment(s) about PE Gate's team" />
                            </div>
                            <Form.Item className="mb_0">
                              {getFieldDecorator("team", {
                                rules: [{ required: true, message: "Please fill out this field" }]
                              })(
                                <TextArea />
                              )}
                            </Form.Item>
                          </div>
                        </div> */}
                        {/* <div className="column small-12 medium-12 p_0">
                          <div className="form-item mb_40">
                            <div className="form-item-label text text-height-20 font-semibold color-grey">
                              Risks
                              <ToolTip placement="rightTop" title="Add any comment(s) about company risks, if any" />
                            </div>
                            <Form.Item className="mb_0">
                              {getFieldDecorator("risks", {
                                rules: [{ required: true, message: "Please fill out this field" }]
                              })(
                                <TextArea />
                              )}
                            </Form.Item>
                          </div>
                        </div> */}
                        {/* <div className="column small-12 medium-12 p_0">
                          <div className="form-item mb_40">
                            <div className="form-item-label text text-height-20 font-semibold color-grey">
                              Historic
                              <ToolTip placement="rightTop" title="Add any comment(s) about historic performance" />
                            </div>
                            <Form.Item className="mb_0">
                              {getFieldDecorator("historic", {
                                rules: [{ required: true, message: "Please fill out this field" }]
                              })(
                                <TextArea />
                              )}
                            </Form.Item>
                          </div>
                        </div> */}
                        {/* <div className="column small-12 medium-12 p_0">
                          <div className="form-item mb_40">
                            <div className="form-item-label text text-height-20 font-semibold color-grey">
                              Projections
                              <ToolTip placement="rightTop" title="Add any comment(s) about company projections" />
                            </div>
                            <Form.Item className="mb_0">
                              {getFieldDecorator("projections", {
                                rules: [{ required: true, message: "Please fill out this field" }]
                              })(
                                <TextArea />
                              )}
                            </Form.Item>
                          </div>
                        </div> */}
                        {/* {
                          this.state?.preformenceSelect.map((select, index) => (
                            <div className="column small-12 medium-12" key={index}>
                              <div className="row form-item mb_40">
                                <div className="medium-4 small-12 align-self-middle form-item-label text text-height-20 font-semibold color-grey">
                                  {select.title}
                                  <ToolTip placement="rightTop" title={select.tooltipTitle} />
                                </div>
                                <Form.Item className="medium-8 small-12 mb_0">
                                  {getFieldDecorator(select.name, {
                                    rules: [{ required: true, message: "Please fill out this field" }]
                                  })(
                                    <Select className="fluid-x" >
                                      {PREFORMENCE.map((i) => <Option key={i.value} value={i.value}>{select.optionNameChange ? i.value : i.title}</Option>)}
                                    </Select>
                                  )}
                                </Form.Item>
                              </div>
                            </div>
                          ))
                        } */}
                        {/* <div className="column small-12 medium-12">
                          <div className="row form-item mb_40">
                            <div className="medium-4 small-12 align-self-middle form-item-label text text-height-20 font-semibold color-grey">
                              Reason For Selling
                              <ToolTip placement="rightTop" title="Select the reason for selling your shares" />
                            </div>
                            <Form.Item className="medium-8 small-12 mb_0">
                              {getFieldDecorator('reason', {
                                rules: [{ required: true, message: "Please fill out this field" }]
                              })(
                                <Select className="fluid-x" onChange={this.selectedReason} >
                                  {findDictionaryByID(37, this.props?.opportunityGroups).map((i) => (
                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                  ))}
                                </Select>
                              )}
                            </Form.Item>
                          </div>
                        </div> */}
                        {/* {
                          this.state.selectedReasonValue
                            ? (
                              <div className="column small-12 medium-12 p_0">
                                <div className="form-item mb_40">
                                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                                    Other Reason
                                  </div>
                                  <Form.Item className="mb_0">
                                    {getFieldDecorator("reason_other", {
                                      rules: [{ required: true, message: "Please fill out this field" }]
                                    })(
                                      <TextArea />
                                    )}
                                  </Form.Item>
                                </div>
                              </div>
                            ) : null
                        } */}
                        {/* {business?.investors < 2 && <>
                          <div className="column small-12 medium-12">
                            <div className="row form-item mb_40">
                              <div className="medium-4 small-12 align-self-middle form-item-label text text-height-20 font-semibold color-grey">
                                Deal duration (days)
                              </div>
                              <Form.Item className="medium-8 small-12 mb_0">
                                {getFieldDecorator("external_duration", {
                                  rules: [{ required: true, message: "Please fill out this field" }]
                                })(
                                  <NumericInput className="fluid-x" />
                                )}
                              </Form.Item>
                            </div>
                          </div>
                          <div className="column small-12 medium-12">
                            <div className="row form-item mb_40">
                              <div className="medium-4 small-12 align-self-middle form-item-label text text-height-20 font-semibold color-grey">
                                Buy Now Price
                              </div>
                              <Form.Item className="medium-8 small-12 mb_0">
                                {getFieldDecorator("external_max_price", {
                                  rules: [{ required: true, message: "Please fill out this field" }]
                                })(
                                  <NumericInput className="fluid-x" />
                                )}
                              </Form.Item>
                            </div>
                          </div>
                        </>} */}
                        <div className="flex-container form-item-lg medium-12">
                          <Form.Item className="mb_5">
                            {getFieldDecorator('check', {
                              valuePropName: "checked",
                              rules: [{ required: true, message: "Please fill out this field" }],
                              // onChange: this.showTermsPopup,
                            })(
                              <Checkbox className="flex-container align-middle">
                                <span className="text text-18 font-semibold color-grey">
                                  I have read and agreed to the
                                  <a href="https://www.pe-gate.com/terms-of-use" target='_blank' rel="noopener noreferrer"> terms </a>
                                  set forth in this agreement.
                                </span>
                              </Checkbox>
                            )}
                          </Form.Item>
                        </div>
                        <div className="flex-container form-item-lg medium-12">
                          <Form.Item className="mb_5">
                            {getFieldDecorator('agree', {
                              valuePropName: "checked",
                              rules: [{ required: true, message: "Please fill out this field" }],
                              onChange: this.showTermsPopup,
                            })(
                              <Checkbox className="flex-container align-middle">
                                <span className="text text-18 font-semibold color-grey">
                                  I agree to sell my shares to shareholders who express interest to purchase my
                                  shares at the Offer Price I set. If no bids are received by auction end,
                                  my shares will not be sold
                                </span>
                              </Checkbox>
                            )}
                          </Form.Item>
                        </div>
                        <div className="flex-container align-center form_submit">
                          <Form.Item className="mb_0">
                            <Button
                              title="Submit to PE-Gate"
                              disabled={!isTermsAndAgreeChecked}
                              prefix={<Icon type="checked" style={{ fontSize: 18, marginRight: 10 }} />}
                              size="xl"
                              theme="blue"
                              className="btn-shadow radius-8"
                              type="submit"
                              loading={handleLoading(loading, "CreateOffers")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            ) : null
        }

        <PopUp
          className="relative_parent"
          visible={popupTermsVisible}
          innerClassNames="shadow-layout bg-white radius-10 relative lg base"
        >
          <div
            onClick={this.hideTermsPopup}
            className="popup_close color-grey-700"
          >
            <Icon type="cancel" />
          </div>
          <div>
            {/* <div className="text text-18 color-grey mb_25 font-bold">
              {
                business?.investors < 2 ?
                  'By signing the below, you agree to sell your shares to the highest bidder at or above the Minimum Offer Price you set above. If no bids are received by auction end, your shares will not be sold.' :
                  'By signing the below, you agree to sell your shares to shareholders who express interest to purchase your shares at the Offer Price you set. If no bids are received by auction end, your shares will not be sold.'
              }
            </div> */}

            <Form.Item className="mb_20">
              {getFieldDecorator("termsSignature", {
                rules: [{ required: true, message: "Please fill out this field" }],
              })(
                <Input placeholder='Name Surname' className="fluid-x" />
              )}
            </Form.Item>

            <div className="btn_group text-right">
              <Button
                title="Cancel"
                size="lg"
                theme="default"
                className="btn-link font-uppercase"
                onClick={this.agreeTermsCancel}
              />
              <Button
                type="submit"
                disabled={!isTermsInputFilled}
                title="I agree"
                size="xl"
                theme="blue"
                className="radius-8"
                style={{ fontSize: 18 }}
                onClick={this.agreeTermsOk}
              />
            </div>
          </div>
        </PopUp>

        <PopUp
          className="relative_parent"
          visible={this.state.submitModalVisible}
          innerClassNames="shadow-layout bg-white radius-10 relative sm base"
        >
          <>
            <div className="popup_icon">
              <img src={require("../../assets/img/completed.svg")} alt="" />
            </div>
            <div className="text-center">
              <div className="flex-container align-center">
                <div className="text text-20 text-height-30 font-medium color-grey mb_45 large-10">
                  Thank you.
                  We will review your submission and be in touch shortly.
                </div>
              </div>

              <Button
                title="OK"
                size="xl"
                theme="blue"
                className="radius-8"
                style={{ fontSize: 18 }}
                onClick={this.closeSubmitModal}
              />
            </div>
          </>
        </PopUp>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  business: state.business.currentOpenedBusiness,
  businessSummary: state.business.businessSummary,

  loading: state.offers.loading,
  errorMessage: state.offers.errorMessage,
  businessLoading: state.business.loading,
  businessErrorMessage: state.business.errorMessage,
  opportunityGroups: state.dictionaries.opportunityGroups,
  userID: state.user.xID,
  businessReports: state.reports.businessReports,
  reportsLoading: state.reports.loading,
  reportsErrorMessage: state.reports.errorMessage,
});

const mapDispatchToProps = dispatch => ({
  createOffer: params => dispatch(createOffers(params)),
  getBusinessSummary: (businessID) => dispatch(businessSummary(businessID)),
  getBusiness: (businessID) => dispatch(getBusiness(businessID)),
  getReport: (userID, currency, businessID) => dispatch(getBusinessReport(userID, currency, businessID))
});

const OfferScreen = Form.create({ name: "offerAmount" })(Offer);

export default connect(mapStateToProps, mapDispatchToProps)(OfferScreen);
