import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Spin, Tabs } from "antd";

import { handleLoading } from "../../utils/handleLoading";
import { searchOffers } from "../../redux/business/thunks/offersThunk";
import TabPaneContent from "../../components/TabPaneContent/TabPaneContent";
import { clearOfferFilters, clearOffers, setActiveOffersTabKey } from "../../redux/business/actions/offersAction";
import FilterComponent from "./OffersFilter";
import TabPaneTitle from "../Opportunities/TabPaneTitle";

const { TabPane } = Tabs;
class Offers extends Component {
  getOffersData = (data) => {
    if(data) {
      return data.map((offer) => ({
        id: offer.id,
        photo: offer.business.photo,
        name: offer.business.name,
        description: offer.team,
        deadline: offer.end,
        offerInfo: {
          isSecondaryDeal: true,
          close: offer.closed,
          scope: offer.scope,
          sharesPrice: offer.shares_price,
          internal: offer.internal,
          external: offer.external,
          // currentBid: offer.mode === 'OPEN' ? offer?.last_bid?.price : '',
          endDate: offer.end,
          currency: offer.business.currency,
          userId: offer.user.id,
        }
      }));
    }
    
    return null;
  };

  infiniteScroll = () => {
    if ((Math.ceil(window.innerHeight + window.scrollY)) >= document.body.offsetHeight) {
      const { offersCount, currentPage } = this.props;
      const roundedCount = Math.ceil(offersCount / 10) * 10;
      const checkingNumber = currentPage * 10;
      if (roundedCount >= checkingNumber && currentPage >= 1) { // for avoiding of calling endless searchOffers() thunk
        const { searchOffers } = this.props;
        searchOffers();
      }
    }
  };

  handleTabsPaneOnChange = (activeTabKey) => {
    const { setActiveOffersTabKey } = this.props;
    setActiveOffersTabKey(activeTabKey);
  };

  getTabPanes = () => {
    const {
      offersList, reviewOffersCount,
      ongoingOffersCount, closedOffersCount, failedOffersCount,
      reviewOffers, ongoingOffers, closedOffers, role, failedOffers,
    } = this.props;

    let tabPanes = [
      {
        key: 'all',
        data: offersList,
        tab: <TabPaneTitle title='All' />,
      },
      {
        key: 'review',
        data: reviewOffers,
        tab: <TabPaneTitle title="In Review" length={reviewOffersCount} />,
        hidden: role !== "ADMIN",
      },
      {
        key: 'ongoing',
        data: ongoingOffers,
        tab: <TabPaneTitle title="Live" length={ongoingOffersCount} />,
      },
      {
        key: 'closed',
        data: closedOffers,
        tab: <TabPaneTitle title="Closed" length={closedOffersCount} />,
      },
      {
        key: 'failed',
        data: failedOffers,
        tab: <TabPaneTitle title="Failed" length={failedOffersCount} />,
      },
    ];

    return tabPanes;
  };

  componentDidUpdate(prevProps) {
    // if (this.props?.offersCount !== prevProps.offersCount && !prevProps.offersCount) {
    //   const { searchOffers } = this.props;
    //   // searchOffers();
    // }
  }

  componentDidMount() {
    const { searchOffers } = this.props;
    if (!this.props.offersCount) {
      searchOffers({}, 0);
    }
    window.addEventListener("scroll", this.infiniteScroll);
  }

  componentWillUnmount() {
    const { clearOffers, clearOfferFilters } = this.props;
    window.removeEventListener("scroll", this.infiniteScroll);
    clearOffers();
    clearOfferFilters();
  }

  render() {
    const { loading, activeTabKey } = this.props;

    return (
      <div className="relative fluid-y screen_wrapper">
        <div className="row">
          <div className="column small-12">
            <div className="page_top page_top-tall">
              <div className="page_title visible-first custom-headline text text-28 text-height-48 font-heavy font-uppercase color-grey">
                <h1>Secondary Deals</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="column small-12" >
            <FilterComponent />
            <div className="small-12">
              <Spin spinning={handleLoading(loading, "SearchOffers")}>
                <Tabs
                  onChange={this.handleTabsPaneOnChange}
                  defaultActiveKey={activeTabKey}
                  className="tab_default tab_sticky"
                >
                  {this.getTabPanes().map(({ key, data, tab, hidden }) => {
                    if (!hidden) {
                      return <TabPane tab={tab} key={key} >
                        <TabPaneContent type={key} businesses={this.getOffersData(data)} />
                      </TabPane>
                    }

                    return null;
                  })}
                </Tabs>
                {/* {!this.props.offersCount
                  ? (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        <span className='color-grey'>
                          There are no available Secondary deals in the moment
                        </span>
                      }
                    />
                  )
                  : (
                    <TabPaneContent type="all" businesses={getOffersList} />
                  )
                } */}
              </Spin>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  role: state.user.role,
  loading: state.offers.loading,
  errorMessage: state.offers.errorMessage,
  offersList: state.offers.offersList,
  closedOffers: state.offers.closedOffers,
  ongoingOffers: state.offers.ongoingOffers,
  reviewOffers: state.offers.reviewOffers,
  failedOffers: state.offers.failedOffers,
  offersCount: state.offers.offersCount,
  closedOffersCount: state.offers.closedOffersCount,
  failedOffersCount: state.offers.failedOffersCount,
  reviewOffersCount: state.offers.reviewOffersCount,
  ongoingOffersCount: state.offers.ongoingOffersCount,
  currentPage: state.offers.currentPage,
  activeTabKey: state.offers.activeTabKey,
});

const mapDispatchToProps = dispatch => ({
  searchOffers: (params, hardCodeOffset) => dispatch(searchOffers(params, hardCodeOffset)),
  clearOffers: () => dispatch(clearOffers()),
  clearOfferFilters: () => dispatch(clearOfferFilters()),
  setActiveOffersTabKey: (activeTabKey) => dispatch(setActiveOffersTabKey(activeTabKey)),
});

const OffersScreen = Form.create({ name: "offers" })(Offers);

export default connect(mapStateToProps, mapDispatchToProps)(OffersScreen);
