import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Tabs,
  Form,
  message,
  Spin,
} from 'antd';

import history from "../../history";
import { setActivePortfolioTabKey } from "../../redux/reports/actions/reportsActions";
import { getClosedReports, getReport } from "../../redux/reports/thunks/reportsThunk";
import { handleErrorMessage } from "../../utils/handleErrorMessage";
import { handleLoading } from "../../utils/handleLoading";

import Icon from "../../components/Icon/Icon";
import InvestmentTab from "./InvestmentTab";
import AnalyticsTab from "./AnalyticsTab";
import './style.scss';

const { TabPane } = Tabs;

class Portfolio extends Component {
  componentDidUpdate(prevProps, prevState) {
    const { errorMessage } = this.props;

    if (handleErrorMessage(errorMessage, prevProps.errorMessage, "GetReport")) {
      message.error(errorMessage.message);
      if (errorMessage.message === "ACCESS PORTFOLIO") {
        history.push("/");
      }
    }
  }

  componentDidMount() {
    window.scroll(0, 0);
    const {getReport, getClosedReports, xID, selectedCurrency} = this.props;
    getReport(xID, selectedCurrency);
    getClosedReports(selectedCurrency);
  }

  render() {
    const { loading, activeTabKey, setActivePortfolioTabKey } = this.props;

    return (
      <Spin spinning={handleLoading(loading, 'GetReport')}>
        <div className="row screen_wrapper">
          <div className="column small-12 page_top page_top-tall">
            <div className="page_title visible-first custom-headline text text-28 text-height-48 font-heavy font-uppercase color-grey">
              <h1>My portfolio</h1>
            </div>
          </div>
          <div className="column small-12">
            <Tabs
              defaultActiveKey={activeTabKey}
              className="tab_default tab_sticky"
              onChange={tabKey => setActivePortfolioTabKey(tabKey)}
            >
              <TabPane
                tab={
                  <div className="flex-container align-middle">
                    <Icon type="analyses" size={17} style={{marginRight: 10}}/>
                    Analytics
                  </div>
                }
                key="analytics"
              >
                <AnalyticsTab />
              </TabPane>
              <TabPane
                tab={
                  <div className="flex-container align-middle">
                    <Icon type="loan" size={25} style={{marginRight: 6}}/>
                    My investments
                  </div>
                }
                key="my_investments"
              >
                <InvestmentTab />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </Spin>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.reports.loading,
  errorMessage: state.reports.errorMessage,
  activeTabKey: state.reports.activeTabKey,
  selectedCurrency: state.reports.selectedCurrency,
  xID: state.user.xID,
});

const mapDispatchToProps = dispatch => ({
  getReport: (investor, currency) => dispatch(getReport(investor, currency)),
  getClosedReports: (currency) => dispatch(getClosedReports(currency)),
  setActivePortfolioTabKey: (tabKey) => dispatch(setActivePortfolioTabKey(tabKey)),
});

const PortfolioScreen = Form.create()(Portfolio);

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioScreen);
