import {
  GET_REPORT_START, GET_REPORT_SUCCESS, GET_REPORT_FAIL,
  GET_NEW_REPORT_START, GET_NEW_REPORT_SUCCESS, GET_NEW_REPORT_FAIL,
  GET_CLOSED_REPORTS_START, GET_CLOSED_REPORTS_SUCCESS, GET_CLOSED_REPORTS_FAIL,
  ADD_REPORT_START, ADD_REPORT_SUCCESS, ADD_REPORT_FAIL,
  ADD_STATS_START, ADD_STATS_SUCCESS, ADD_STATS_FAIL,
  REMOVE_REPORTS, SET_ACTIVE_PORTFOLIO_TAB_KEY, SET_ACTIVE_INVESTMENT_ID, GET_BUSINESS_REPORTS_START, GET_BUSINESS_REPORTS_SUCCESS, GET_BUSINESS_REPORTS_FAIL,
} from "../../constants";

export const getReportStart = () => ({
  type: GET_REPORT_START
});

export const getReportSuccess = payload => ({
  type: GET_REPORT_SUCCESS,
  payload
});

export const getReportFail = payload => ({
  type: GET_REPORT_FAIL,
  payload
});

export const getBusinessReportsStart = () => ({
  type: GET_BUSINESS_REPORTS_START
});

export const getBusinessReportsSuccess = payload => ({
  type: GET_BUSINESS_REPORTS_SUCCESS,
  payload
});

export const getBusinessReportsFail = payload => ({
  type: GET_BUSINESS_REPORTS_FAIL,
  payload
});

export const getNewReportStart = () => ({ type: GET_NEW_REPORT_START });

export const getNewReportSuccess = payload => ({
  type: GET_NEW_REPORT_SUCCESS,
  payload,
});

export const getNewReportFail = payload => ({
  type: GET_NEW_REPORT_FAIL,
  payload,
});

export const getClosedReportsStart = () => ({ type: GET_CLOSED_REPORTS_START });

export const getClosedReportsSuccess = payload => ({
  type: GET_CLOSED_REPORTS_SUCCESS,
  payload,
});

export const getClosedReportsFail = payload => ({
  type: GET_CLOSED_REPORTS_FAIL,
  payload,
});

export const addReportStart = () => ({
  type: ADD_REPORT_START
});

export const addReportSuccess = payload => ({
  type: ADD_REPORT_SUCCESS,
  payload
});

export const addReportFail = payload => ({
  type: ADD_REPORT_FAIL,
  payload
});

export const addStatsStart = () => ({ type: ADD_STATS_START });

export const addStatsSuccess = payload => ({
  type: ADD_STATS_SUCCESS,
  payload,
});

export const addStatsFail = payload => ({
  type: ADD_STATS_FAIL,
  payload,
});

export const removeReports = () => ({
  type: REMOVE_REPORTS
});

export const setActivePortfolioTabKey = (tabKey) => ({
  type: SET_ACTIVE_PORTFOLIO_TAB_KEY,
  tabKey,
});

export const setActiveInvestmentId = (activeInvestmentId) => ({
  type: SET_ACTIVE_INVESTMENT_ID,
  activeInvestmentId,
});
