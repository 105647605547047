import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setSaved } from "../../redux/business/thunks/businessThunk";

import loadingGif from "../../assets/img/loading.gif";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";
import "./style.scss";
import { compareTimes } from "../../utils/calculateTime";
import { formatNumber } from '../../utils/formatNumber';

const ListItem = (props) => {
  const {
    img,
    className,
    id,
    href,
    title,
    desc,
    tag,
    orderTag,
    footer,
    style,
    saved,
    role,
    deadline,
    hideSaveIcon,
    offerInfo,
  } = props;
  const [isImageLoading, setIsImageLoading] = useState(true);
  const isExternal = offerInfo?.scope === "EXTERNAL";
  const startPrice = isExternal ? offerInfo?.external?.min : offerInfo?.internal?.min;

  const handleImageLoaded = () => {
    setIsImageLoading(false);
  };

  const handleImageErrored = () => {
    setIsImageLoading(false);
  };

  const toggleSaved = (event) => {
    event.preventDefault();

    const { setSaved } = props;
    setSaved(id, saved ? 0 : 1);
  };

  const getListingBy = () => {
    if(offerInfo?.userId !== props?.userInfo?.id) {
      if(offerInfo?.scope === "INTERNAL") {
        return "Co-Shareholders";
      } else {
        return "All investors";
      }
    }

    return "My listing";
  }

  return (
    <Link to={href} className={`list_item clip bg-white radius-10 shadow-layout ${className}`} style={style}>
      <div className="list_item__img">
        {
          role === 'ADMIN' && orderTag ?
            <div className="c-order-tag text text-10 font-medium color-grey font-uppercase bg-white shadow-tag radius-4">
              order: {orderTag}
            </div> : null
        }
        <img
          src={isImageLoading ? loadingGif : img}
          alt=""
          className="fluid-x"
          onLoad={handleImageLoaded}
          onError={handleImageErrored}
        />

        {
          tag ?
            <div className="c-tag text text-10 font-medium color-grey font-uppercase bg-white shadow-tag radius-4">
              {tag}
            </div> : null
        }
      </div>
      <div className="flex-container flex-dir-column fluid-y list_item_caption">
        <div className="list_item__title custom-headline text text-18 font-heavy color-grey">
          <h3>{title}</h3>
        </div>
        {/* {!offerInfo?.mode ?
          <div className="list_item__desc flex-child-grow text text-14 text-height-20 color-grey-900">
            {desc}
          </div> :
            offerInfo.close || deadline ?
              <>
                <div className="list_item__desc text text-14 text-height-20 color-grey-900">
                  Available For: &nbsp; {getListingBy()}
                </div>
                <div className="list_item__desc flex-child-grow text text-14 text-height-20 color-grey-900">
                  Auction Has Ended
                </div>
              </> : <>
                <div className="list_item__desc flex-child-grow text text-14 text-height-20 color-grey-900">
                  Starting Value:  &nbsp; {offerInfo.startPrice} {offerInfo.currency}
                </div>
                <div className="list_item__desc flex-child-grow text text-14 text-height-20 color-grey-900">
                  Available For: &nbsp; {getListingBy()}
                </div>
                {<div className="list_item__desc flex-child-grow text text-14 text-height-20 color-grey-900">
                  Current Bid:  &nbsp; {offerInfo.mode === 'BLIND' ? 'not available' : offerInfo.currentBid ? `${offerInfo.currentBid}  ${offerInfo.currency}` : 'no bid'}
                </div> }
              </>
          } */}
        {!offerInfo?.isSecondaryDeal ?
          <div className="list_item__desc flex-child-grow text text-14 text-height-20 color-grey-900">
            {desc}
          </div> :
           offerInfo.close || !compareTimes(deadline) ?
            <>
              <div className="list_item__desc flex-child-grow text text-14 text-height-20 color-grey-900">
                For: &nbsp; {getListingBy()}
              </div>
              {
                deadline &&
                <div className="list_item__desc flex-child-grow text text-14 text-height-20 color-grey-900">
                  Auction Has Ended
                </div>
              }
            </>
            : <>
              <div className="list_item__desc flex-child-grow text text-14 text-height-20 color-grey-900">
                Starting Value:  &nbsp; {formatNumber(startPrice)} {isExternal ? offerInfo.currency : "Shares"}
              </div>
              <div className="list_item__desc flex-child-grow text text-14 text-height-20 color-grey-900">
                For: &nbsp; {getListingBy()}
              </div>
            </>
        }
      <div className="footer-wrapper">
        <div className="list_item__footer flex-container flex-wrap align-middle text text-14 color-grey-900">
          {footer}
        </div>
        {
          role !== "ADMIN" && !hideSaveIcon ?
            <Button
              title={<Icon className={saved ? "color-red" : "color-grey-800"} type={saved ? "heart-filled" : "heart"} />}
              className="btn-initial save_btn"
              onClick={toggleSaved}
            />
            : null
        }
      </div>
    </div>
    </Link >
  )
};

const mapStateToProps = state => ({
  role: state.user.role,
  userInfo: state.user.userInfo,
});

const mapDispatchToProps = dispatch => ({
  setSaved: (business, saved) => dispatch(setSaved(business, saved)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
