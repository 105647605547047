import { message } from 'antd';
import CommonUtils from "../../../utils/CommonUtils";
import {
  acceptBidRequestFail,
  acceptBidRequestStart,
  acceptBidRequestSuccess,
  addExternalBidFail, addExternalBidStart, addExternalBidSuccess,
  addExternalOverBid,
  addInternalBidFail, addInternalBidStart, addInternalBidSuccess,
  closeOfferFail,
  closeOfferStart,
  closeOfferSuccess,
  createOfferFail, createOfferStart, createOfferSuccess,
  externalPublishFail, externalPublishStart, externalPublishSuccess,
  getBidFail, getBidStart, getBidSuccess,
  getOfferBidRequestFail,
  getOfferBidRequestStart,
  getOfferBidRequestSuccess,
  getOfferFail, getOfferStart, getOfferSuccess,
  internalPublishFail, internalPublishStart, internalPublishSuccess,
  moveBidFail,
  moveBidStart,
  moveBidSuccess,
  rejectBidRequestFail,
  rejectBidRequestStart,
  rejectBidRequestSuccess,
  removeOfferAdminFail, removeOfferAdminStart, removeOfferAdminSuccess,
  removeOfferInvestorFail, removeOfferInvestorStart, removeOfferInvestorSuccess,
  requestExternalSaleFail, requestExternalSaleStart, requestExternalSaleSuccess,
  searchBidsFail, searchBidsStart, searchBidsSuccess,
  searchOfferBidRequestsFail,
  searchOfferBidRequestsStart,
  searchOfferBidRequestsSuccess,
  searchOfferBidsFail, searchOfferBidsStart, searchOfferBidsSuccess,
  searchOffersFail, searchOffersStart, searchOffersSuccess,
  setOfferFilters,
  setWonFail, setWonStart, setWonSuccess
} from "../actions/offersAction";

/// ---------------------- SHARED -------------------------

// searchOffers // +++

export const searchOffers = (params, hardCodeOffset) => ((dispatch, getState) => {
  dispatch(searchOffersStart());
  if (params?.offerFilters) dispatch(setOfferFilters(params?.offerFilters))
  const { xID, xTOKEN, role } = getState().user;
  const { currentPage, offerFilters } = getState().offers;
  const perPageSize = 12;
  const filters = params?.offerFilters || offerFilters
  const config = { id: xID, token: xTOKEN };
  CommonUtils.apiCall("get", "private", `shared/searchOffers/${currentPage * 10}/${perPageSize}?cnt`, { ...filters }, config)
    .then(offersCountResponse => {
      if (offersCountResponse.data !== null) {
        const offersCount = offersCountResponse.data;
        CommonUtils.apiCall("get", "private", `shared/searchOffers/${currentPage * 10}/${perPageSize}?cnt&closed`, { ...filters }, config)
          .then(closedOffersCountResponse => {
            if (closedOffersCountResponse.data !== null) {
              const closedOffersCount = closedOffersCountResponse.data;
              CommonUtils.apiCall("get", "private", `shared/searchOffers/${currentPage * 10}/${perPageSize}?cnt&failed`, { ...filters }, config)
              .then(failedOffersCountResponse => {
                if (failedOffersCountResponse.data !== null) {
                  const failedOffersCount = failedOffersCountResponse.data;
                  CommonUtils.apiCall("get", "private", `shared/searchOffers/${currentPage * 10}/${perPageSize}?cnt&ongoing`, { ...filters }, config)
                    .then(ongoingOffersCountResponse => {
                      if (ongoingOffersCountResponse.data !== null) {
                        const ongoingOffersCount = ongoingOffersCountResponse.data;
                        CommonUtils.apiCall("get", "private", `shared/searchOffers/${currentPage * 10}/${perPageSize}`, { ...filters }, config)
                          .then(response => {
                            if (Array.isArray(response.data)) {
                              CommonUtils.apiCall("get", "private", `shared/searchOffers/${currentPage * 10}/${perPageSize}?closed`, { ...filters }, config)
                                .then(closedOffersResponse => {
                                  if (Array.isArray(closedOffersResponse.data)) {
                                    CommonUtils.apiCall("get", "private", `shared/searchOffers/${currentPage * 10}/${perPageSize}?ongoing`, { ...filters }, config)
                                      .then(ongoingOffersResponse => {
                                        if (Array.isArray(ongoingOffersResponse.data)) {
                                          CommonUtils.apiCall("get", "private", `shared/searchOffers/${currentPage * 10}/${perPageSize}?failed`, { ...filters }, config)
                                            .then(failedOffersResponse => {
                                              if (Array.isArray(failedOffersResponse.data)) {
                                                const scrolledOffersList = response.data;
                                                const closedOffers = closedOffersResponse.data;
                                                const ongoingOffers = ongoingOffersResponse.data;
                                                const failedOffers = failedOffersResponse.data;
          
                                                const oldOffersList = getState().offers.offersList ? getState().offers.offersList : [];
                                                const oldClosedOffers = getState().offers.closedOffers ? getState().offers.closedOffers : [];
                                                const oldOngoingOffers = getState().offers.ongoingOffers ? getState().offers.ongoingOffers : [];
                                                const oldFailedOffers = getState().offers.failedOffers ? getState().offers.failedOffers : [];
          
                                                const updatedOffersList = hardCodeOffset !== undefined ? scrolledOffersList : oldOffersList.concat(scrolledOffersList);
                                                const updatedClosedOffers = hardCodeOffset !== undefined ? closedOffers : oldClosedOffers.concat(closedOffers);
                                                const updatedOngoingOffers = hardCodeOffset !== undefined ? ongoingOffers : oldOngoingOffers.concat(ongoingOffers);
                                                const updatedFailedOffers = hardCodeOffset !== undefined ? failedOffers : oldFailedOffers.concat(failedOffers);
                                                
                                                if (role !== "ADMIN") {
                                                  dispatch(searchOffersSuccess({ 
                                                    offersList: updatedOffersList,
                                                    closedOffers: updatedClosedOffers,
                                                    ongoingOffers: updatedOngoingOffers,
                                                    failedOffers: updatedFailedOffers,
                                                    offersCount,
                                                    closedOffersCount,
                                                    failedOffersCount,
                                                    ongoingOffersCount,
                                                  }))
                                                } else {
                                                  CommonUtils.apiCall("get", "private", `shared/searchOffers/${currentPage * 10}/${perPageSize}?cnt&review`, { ...filters }, config)
                                                    .then(reviewOffersCountResponse => {
                                                      if (reviewOffersCountResponse.data !== null) {
                                                        const reviewOffersCount = reviewOffersCountResponse.data;
                                                        CommonUtils.apiCall("get", "private", `shared/searchOffers/${currentPage * 10}/${perPageSize}?review`, { ...filters }, config)
                                                          .then(reviewOffersResponse => {
                                                            if (Array.isArray(reviewOffersResponse.data)) {
                                                              const reviewOffers = reviewOffersResponse.data;
                                                              const oldReviewOffers = getState().offers.reviewOffers ? getState().offers.reviewOffers : [];
                                                              const updatedReviewOffers = hardCodeOffset !== undefined ? reviewOffers : oldReviewOffers.concat(reviewOffers);
          
                                                              dispatch(searchOffersSuccess({ 
                                                                offersList: updatedOffersList,
                                                                closedOffers: updatedClosedOffers,
                                                                ongoingOffers: updatedOngoingOffers,
                                                                reviewOffers: updatedReviewOffers,
                                                                failedOffers: updatedFailedOffers,
                                                                offersCount,
                                                                closedOffersCount,  
                                                                ongoingOffersCount,
                                                                failedOffersCount,
                                                                reviewOffersCount,
                                                              }))
                                                            } else dispatch(searchOffersFail(reviewOffersResponse.data.error));
                                                          })
                                                          .catch(() => dispatch(searchOffersFail("Error")));
                                                      } else dispatch(searchOffersFail("Error"));
                                                    })
                                                    .catch(() => dispatch(searchOffersFail("Error")));
                                                }
          
                                              } else dispatch(searchOffersFail(failedOffersResponse.data.error));
                                            })
                                            .catch(() => dispatch(searchOffersFail("Error")));
                                        } else dispatch(searchOffersFail(ongoingOffersResponse.data.error));
                                      })
                                      .catch(() => dispatch(searchOffersFail("Error")));
                                  } else dispatch(searchOffersFail(closedOffersResponse.data.error));
                                })
                                .catch(() => dispatch(searchOffersFail("Error")));
                            } else dispatch(searchOffersFail(response.data.error));
                          })
                          .catch(() => dispatch(searchOffersFail("Error")));
                      } else dispatch(searchOffersFail("Error"));
                    })
                    .catch(() => dispatch(searchOffersFail("Error")));
                }
              })
              .catch(() => dispatch(searchOffersFail("Error")));
            } else dispatch(searchOffersFail("Error"));
          })
          .catch(() => dispatch(searchOffersFail("Error")));
      } else dispatch(searchOffersFail("Error"));
    })
    .catch(() => dispatch(searchOffersFail("Error")));
});

// searchOfferBids //
export const searchOfferBidsCount = params => ((dispatch, getState) => {
  dispatch(searchOfferBidsStart());
  const { xID, xTOKEN } = getState().user;
  const defaultPageSize = 10;
  const config = { id: xID, token: xTOKEN };

    CommonUtils.apiCall("get", "private", `shared/searchOfferBids/${params.offer}/0/${defaultPageSize}?cnt`, { ...params.query }, config)
    .then(response => {
      if (response.data) {
        dispatch(searchOfferBidsSuccess({ data: response.data }));
      }
      else dispatch(searchOfferBidsFail(response.data.error));
    })
    .catch(() => dispatch(searchOfferBidsFail("Error")));
});
export const searchOfferBids = params => ((dispatch, getState) => {
  dispatch(searchOfferBidsStart());
  const { xID, xTOKEN } = getState().user;
  const defaultPageSize = 10;
  const config = { id: xID, token: xTOKEN };
  const current = params.current * defaultPageSize || 0;

  if (params.current) {
    CommonUtils.apiCall("get", "private", `shared/searchOfferBids/${params.offer}/${current}/${current + defaultPageSize}`, { ...params.query }, config)
    .then(response => {
      if (response.data) {
        dispatch(searchOfferBidsSuccess({ data: response.data, currentPage: current }));
      }
      else dispatch(searchOfferBidsFail(response.data.error));
    })
    .catch(() => dispatch(searchOfferBidsFail("Error")));
    return;
  }

  CommonUtils.apiCall("get", "private", `shared/searchOfferBids/${params.offer}/0/${defaultPageSize}?cnt`, { ...params.query } ,  config)
    .then(allCntResponse => {
      if (allCntResponse.data !== null) {
        CommonUtils.apiCall("get", "private", `shared/searchOfferBids/${params.offer}/0/${defaultPageSize}?cnt&scope=INTERNAL`, { ...params.query }, config)
          .then(internalCntResponse => {
            if (internalCntResponse.data !== null) {
              CommonUtils.apiCall("get", "private", `shared/searchOfferBids/${params.offer}/0/${defaultPageSize}?cnt&scope=EXTERNAL`, { ...params.query }, config)
              .then(externalCntResponse => {
                if (externalCntResponse.data !== null && allCntResponse.data) {
                    CommonUtils.apiCall("get", "private", `shared/searchOfferBids/${params.offer}/0/${defaultPageSize}` ,{ ...params.query }, config)
                      .then(response => {
                        if (response.data !== null) {
                            CommonUtils.apiCall("get", "private", `shared/searchOfferBids/${params.offer}/0/${defaultPageSize}?scope=INTERNAL`, { ...params.query }, config)
                              .then(internalResponse => {
                                if (internalResponse.data !== null) {
                                    CommonUtils.apiCall("get", "private", `shared/searchOfferBids/${params.offer}/0/${defaultPageSize}?scope=EXTERNAL`, { ...params.query }, config)
                                    .then(externalResponse => {
                                      if (externalResponse.data !== null) {
                                          const allBidsCntResponse = allCntResponse.data;
                                          const internalBidsCntResponse = internalCntResponse.data;
                                          const externalBidsCntResponse = externalCntResponse.data;
                                          const allBidsResponse = response.data;
                                          const internalBidsResponse = internalResponse.data;
                                          const externalBidsResponse = externalResponse.data;
                                          
                                          dispatch(searchOfferBidsSuccess({
                                            allBidsCount: allBidsCntResponse,
                                            internalBidsCount: internalBidsCntResponse,
                                            externalBidsCount: externalBidsCntResponse,
                                            allBids: allBidsResponse,
                                            internalBids: internalBidsResponse,
                                            externalBids: externalBidsResponse,
                                          }));
                                        }
                                        else dispatch(searchOfferBidsFail(externalResponse.data.error));
                                      })
                                      .catch(() => dispatch(searchOfferBidsFail("Error")));
                                  }
                                  else dispatch(searchOfferBidsFail(internalResponse.data.error));
                                })
                                .catch(() => dispatch(searchOfferBidsFail("Error")));
                          }
                          else dispatch(searchOfferBidsFail(response.data.error));
                        })
                        .catch(() => dispatch(searchOfferBidsFail("Error")));
                  }
                  else dispatch(searchOfferBidsFail(externalCntResponse.data.error));
                })
                .catch(() => dispatch(searchOfferBidsFail("Error")));
            }
            else dispatch(searchOfferBidsFail(internalCntResponse.data.error));
          })
          .catch(() => dispatch(searchOfferBidsFail("Error")));
      }
      else dispatch(searchOfferBidsFail(allCntResponse.data.error));
    })
    .catch(() => dispatch(searchOfferBidsFail("Error")));
});

export const searchOfferBidRequests = params => ((dispatch, getState) => {
  dispatch(searchOfferBidRequestsStart());
  const { xID, xTOKEN } = getState().user;
  const defaultPageSize = 10;
  const config = { id: xID, token: xTOKEN };
  const current = params.current * defaultPageSize || 0;

  if (params.current) {
    CommonUtils.apiCall("get", "private", `admin/searchOfferBidRequests/${params.offer}/${current}/${current + defaultPageSize}`, {}, config)
    .then(response => {
      if (response.data) {
        dispatch(searchOfferBidRequestsSuccess({ data: response.data, currentPage: current }));
      }
      else dispatch(searchOfferBidRequestsFail(response.data.error));
    })
    .catch(() => dispatch(searchOfferBidRequestsFail("Error")));
    return;
  }

  CommonUtils.apiCall("get", "private", `admin/searchOfferBidRequests/${params.offer}/0/${defaultPageSize}?cnt`, {} ,  config)
    .then(allCntResponse => {
      if (allCntResponse.data !== null) {
        CommonUtils.apiCall("get", "private", `admin/searchOfferBidRequests/${params.offer}/0/${defaultPageSize}` ,{}, config)
          .then(response => {
            if (response.data !== null) {
              const bidRequestsCntResponse = allCntResponse.data;
              const bidRequestsResponse = response.data;
              
              dispatch(searchOfferBidRequestsSuccess({
                bidRequestsCount: bidRequestsCntResponse,
                bidRequests: bidRequestsResponse,
              }));
            } else dispatch(searchOfferBidRequestsFail(response.data.error));
          })
            .catch(() => dispatch(searchOfferBidRequestsFail("Error")));
            
      }
      else dispatch(searchOfferBidRequestsFail(allCntResponse.data.error));
    })
    .catch(() => dispatch(searchOfferBidRequestsFail("Error")));
});

export const getOfferBidRequest = params => ((dispatch, getState) => {
  dispatch(getOfferBidRequestStart());
  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };
  CommonUtils.apiCall("get", "private", `investor/getOfferBidRequest/${params.offer}`, {} , config)
    .then(response => {
      if (!response.data.error) {
        dispatch(getOfferBidRequestSuccess({ data: response.data }))
      } else dispatch(getOfferBidRequestFail(response.data.error));
    })
    .catch(() => dispatch(acceptBidRequestFail("Error")));
});

export const acceptBidRequest = params => ((dispatch, getState) => {
  dispatch(acceptBidRequestStart());
  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };
  CommonUtils.apiCall("post", "private", `admin/acceptBidRequest`, params, config)
    .then(response => {
      if (!response.data.error) dispatch(acceptBidRequestSuccess());
      else dispatch(acceptBidRequestFail(response.data.error));
    })
    .catch(() => dispatch(acceptBidRequestFail("Error")));
});

export const rejectBidRequest = params => ((dispatch, getState) => {
  dispatch(rejectBidRequestStart());
  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };
  CommonUtils.apiCall("post", "private", `admin/rejectBidRequest`, params, config)
    .then(response => {
      if (!response.data.error) dispatch(rejectBidRequestSuccess());
      else dispatch(rejectBidRequestFail(response.data.error));
    })
    .catch(() => dispatch(rejectBidRequestFail("Error")));
});

// getOffer // +++

export const getOffer = params => ((dispatch, getState) => {
  dispatch(getOfferStart());
  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };
  CommonUtils.apiCall("get", "private", `shared/getOffer/${params.id}`, {}, config)
    .then(response => {
      if (!response.data.error) dispatch(getOfferSuccess(response.data.success));
      else dispatch(getOfferFail(response.data.error));
    })
    .catch(() => dispatch(getOfferFail("Error")));
});

// getBid //

export const getBid = params => ((dispatch, getState) => {
  dispatch(getBidStart());
  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };
  CommonUtils.apiCall("get", "private", `shared/getBid/${params.offerId}/${params.bidder}`, {}, config)
    .then(response => {
      if (response.data) dispatch(getBidSuccess(response.data));
      else dispatch(getBidFail(response.data.error));
    })
    .catch(() => dispatch(getBidFail("Error")));
});

/// ---------------------- INVESTOR -------------------------

// createOffer (postForSale) // +++

export const createOffers = params => ((dispatch, getState) => {
  dispatch(createOfferStart());
  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };
  CommonUtils.apiCall("post", "private", "investor/postForSale", params, config)
    .then(response => {
      if (response.data.success) dispatch(createOfferSuccess({}));
      else dispatch(createOfferFail(response.data.error));
    })
    .catch(() => dispatch(createOfferFail("Error")));
});

// requestExternalSale //  +++

export const requestExternalSales = params => ((dispatch, getState) => {
  dispatch(requestExternalSaleStart());
  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };
  CommonUtils.apiCall("post", "private", "investor/requestExternalSale", params, config)
    .then(response => {
      if (response.data.success) {
        dispatch(requestExternalSaleSuccess({}))
      } else dispatch(requestExternalSaleFail(response.data.error));
    })
    .catch(() => dispatch(requestExternalSaleFail("Error")));
});

// addBid // +++

export const addInternalBid = params => ((dispatch, getState) => {
  dispatch(addInternalBidStart());
  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };
  CommonUtils.apiCall("post", "private", `investor/internalBid`, params, config)
    .then(response => {
      if (response.data.success) {
        dispatch(addInternalBidSuccess({}))
      } else dispatch(addInternalBidFail(response.data.error));
    })
    .catch(() => dispatch(addInternalBidFail("Error")));
});

export const addExternalBid = params => ((dispatch, getState) => {
  dispatch(addExternalBidStart());
  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };
  CommonUtils.apiCall("post", "private", `investor/externalBid`, params, config)
    .then(response => {
      if (response.data.success) {
        dispatch(addExternalBidSuccess({}))
      } else if(response.data.error === "Overbid") {
        message.success("Thank you for your bid. This bid has been flagged and will need to be approved by Admin. We will contact you shortly.");
        dispatch(addExternalOverBid({}));
      } else dispatch(addExternalBidFail(response.data.error));
    })
    .catch(() => dispatch(addExternalBidFail("Error")));
});

// searchBids // 

export const searchBids = params => ((dispatch, getState) => {
  dispatch(searchBidsStart());
  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };
  CommonUtils.apiCall("get", "private", `investor/bids/0/100`, params, config)
    .then(response => {
      if (response.data) {
        dispatch(searchBidsSuccess(response.data))
      } else dispatch(searchBidsFail(response.data.error));
    })
    .catch(() => dispatch(searchBidsFail("Error")));
});

// remove offer (investor) // +++

export const removeOfferInvestor = params => ((dispatch, getState) => {
  dispatch(removeOfferInvestorStart());
  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };
  CommonUtils.apiCall("post", "private", "investor/removeOffer", params, config)
    .then(response => {
      if (response.data.success) dispatch(removeOfferInvestorSuccess())
      else dispatch(removeOfferInvestorFail(response.data.error));
    })
    .catch(() => dispatch(removeOfferInvestorFail("Error")));
});

/// ---------------------- ADMIN -------------------------

// internalPublish // +++

export const internalPublish = params => ((dispatch, getState) => {
  dispatch(internalPublishStart());
  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };
  CommonUtils.apiCall("post", "private", "admin/internalPublish", params, config)
    .then(response => {
      if (response.data.success) dispatch(internalPublishSuccess({ start: params.start, end: params.end }))
      else dispatch(internalPublishFail(response.data.error));
    })
    .catch(() => dispatch(internalPublishFail("Error")));
});

// externalPublish // +++

export const externalPublish = params => ((dispatch, getState) => {
  dispatch(externalPublishStart());
  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };
  const { start, end } = params;
  CommonUtils.apiCall("post", "private", "admin/externalPublish", params, config)
    .then(response => {
      if (response.data.success) dispatch(externalPublishSuccess({ start, end }))
      else dispatch(externalPublishFail(response.data.error));
    })
    .catch((error) => dispatch(externalPublishFail("Error")));
});

// remove offer (admin) // +++

export const removeOfferAdmin = params => ((dispatch, getState) => {
  dispatch(removeOfferAdminStart());
  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };
  CommonUtils.apiCall("post", "private", "admin/removeOffer", params, config)
    .then(response => {
      if (response.data.success) dispatch(removeOfferAdminSuccess())
      else dispatch(removeOfferAdminFail(response.data.error));
    })
    .catch(() => dispatch(removeOfferAdminFail("Error")));
});

// setWon // +++

export const setWon = params => ((dispatch, getState) => {
  dispatch(setWonStart());
  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };
  CommonUtils.apiCall("post", "private", "admin/setWon", params, config)
    .then(response => {
      if (response.data.success) {
        dispatch(setWonSuccess({ id: params.id }))
      } else dispatch(setWonFail(response.data.error));
    })
    .catch(() => dispatch(setWonFail("Error")));
});

// closeOffer

export const closeOffer = params => ((dispatch, getState) => {
  dispatch(closeOfferStart());
  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };

  CommonUtils.apiCall("post", "private", "admin/closeOffer", params, config)
    .then(response => {
      if (response.data.success) {
        dispatch(closeOfferSuccess())
      } else dispatch(closeOfferFail(response.data.error));
    })
    .catch(() => dispatch(closeOfferFail("Error")));
});

// moveBid

export const moveBid = params => ((dispatch, getState) => {
  dispatch(moveBidStart());
  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };

  CommonUtils.apiCall("post", "private", "admin/moveBid", params, config)
    .then(response => {
      if (response.data.success) {
        dispatch(moveBidSuccess())
      } else dispatch(moveBidFail(response.data.error));
    })
    .catch(() => dispatch(moveBidFail("Error")));
});
